import React from 'react';

// import {ReactComponent as Icon3} from '../../assets/images/svg/advisory.svg';
// import {ReactComponent as Icon1} from '../../assets/images/svg/token.svg';

import {ReactComponent as Icon3} from '../../assets/images/svg/advisory.svg';
import {ReactComponent as Icon2} from '../../assets/images/svg/growth.svg';
import tokenIcon from '../../assets/images/token.png';
import {ReactComponent as Icon4} from '../../assets/images/svg/badge.svg';
import {ReactComponent as Icon5} from '../../assets/images/svg/performance.svg';
import { BanknotesIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';

const data = [
  {
    id: 1,
    icon: <Icon3  />,
    title: "Advisory Program",
    text: "Advisory, incubation and introductions to key industry players."
  },
  {
    id: 2,
    icon: <img src={tokenIcon} alt="Token Icon" />,
    title: "Tokenomics Design",
    text: "Vesting schedule, token utility, staking programs, liquidity, mining, and more."
  },
  {
    id: 3,
    icon: <BanknotesIcon />,
    title: "Follow-on Capital",
    text: "Introductions to investors and funds."
  },
  {
    id: 4,
    icon: <Icon5 />,
    title: "Marketing Support",
    text: "Go-to-Market, community and campaigns."
  },
  {
    id: 5,
    icon: <Icon4 />,
    title: "Branding Strategy",
    text: "Digital culture, brand positioning and market assessment."
  },
  {
    id: 6,
    icon: <Icon2/>,
    title: "Listing & Scaling",
    text: "Exchanges, market makers, liquidity and auditors."
  }
]

function Support() {
  return (
    <section className="tf-blog-detail">
      <div className="tf-container">
        <div className="row justify-content-center">
          <div className="col-md-12">
            <div className="detail-inner">
              <div className="content-top">
                <h4 className="title">All-encompassing support</h4>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          {data.map((item, index) => {
            return (
              <div className="col-lg-4 col-md-6" key={item.id}>
                <div className="card-support">
                  <div className="icon-column">
                    <div className='icon-column-inner'>
                      <div className="icon">
                        {item.icon}
                      </div>
                    </div>
                  </div>
                  <div className="content-column">
                    <h2 className="card-title-support">{item.title}</h2>
                    <p className="card-description-support">{item.text}</p>
                  </div>
                </div>
              </div>
            )
          })}

          {/* <div className="col-lg-4 col-md-6">
            <div className="card-support">
              <div className="icon-column">
                <img src={icon2} alt="Icon" className="icon" />
              </div>
              <div className="content-column">
                <h2 className="card-title-support">title</h2>
                <p className="card-description-support">description</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="card-support">
              <div className="icon-column">
                <img src={icon3} alt="Icon" className="icon" />
              </div>
              <div className="content-column">
                <h2 className="card-title-support">title</h2>
                <p className="card-description-support">description</p>
              </div>
            </div>
          </div> */}
        </div>
        <div className='button-section'>
          <Link to={`/benefits`} >
            <div className="launch-button mt-4">
              Learn More
            </div>
          </Link>
        </div>
      </div>
    </section>
  );
}

export default Support;

