import React, { useState, useEffect } from "react";
import PageTitle from "../components/pagetitle/PageTitle";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation, useNavigate } from "react-router-dom";

import "react-quill/dist/quill.snow.css";
import "../css/create.css";
import { useDashboard } from "../hooks/dashboard";

CreateProposal.propTypes = {};

function CreateProposal(props) {
  const {
    title,
    setTitle,
    dec,
    setDec,
    start,
    setStart,
    end,
    setEnd,
    // setTotal,
    // created,
    handleAdd,
    handleUpdate,
  } = useDashboard();
  const navigate = useNavigate();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(false);

  const handleSave = async () => {
    setIsLoading(true);
    try {
      await handleAdd();
      
    } catch (error) {
      toast.error("An error occurred. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  function showError(fieldName) {
    toast.error(`Please ${fieldName}`, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return (
    <div>
      <PageTitle none="none" title="Create Proposal" />

      <section className="tf-add-nft">
        <div className="tf-container">
          <div className="row ">
            <div className="col-xl-9 col-lg-8 ">
              <div className="add-nft-inner">
                <fieldset>
                  <label>Title *</label>
                  <input
                    type="text"
                    placeholder="Enter Your Title Here"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    style={{color:'var(--primary-color5)'}}

                  />
                </fieldset>

                <fieldset>
                  <label>Description*</label>
                  <input
                    type="text"
                    placeholder="Enter The Description Here"
                    value={dec}
                    onChange={(e) => setDec(e.target.value)}
                    style={{color:'var(--primary-color5)'}}

                  />
                </fieldset>

                <fieldset>
                  <label>Start (Date and Time)*</label>
                  <input
                    type="datetime-local"
                    placeholder="Enter The Time Here"
                    value={start}
                    onChange={(e) => setStart(e.target.value)}
                    style={{color:'var(--primary-color5)'}}

                  />
                </fieldset>

                <fieldset>
                  <label>End (Date and Time)*</label>
                  <input
                    type="datetime-local"
                    placeholder="Enter The End Time Here"
                    value={end}
                    onChange={(e) => setEnd(e.target.value)}
                    style={{color:'var(--primary-color5)'}}

                  />
                </fieldset>
                {/* <fieldset>
                  <label>Proposal Created *</label>
                  <input
                    type="text"
                    placeholder="Enter Here"
                    value={created}
                    onChange={(e) => setTotal(e.target.value)}
                    style={{color:'var(--primary-color5)'}}
                  />
                </fieldset> */}
                {location?.state ? (
                  <div className="bottom-button">

                  <button
                    onClick={() =>
                      handleUpdate(location?.state?.dataObj?.proposal_id)
                    }
                    className="me-3"
                    color="success"
                    >
                    {"Update"}
                  </button>
                    </div>
                ) : (
                  <div className="bottom-button">
                    <button
                      disabled={isLoading}
                      onClick={handleSave}
                      className="tf-button active"
                    >
                      {isLoading ? "Loading..." : "Save"}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default CreateProposal;
