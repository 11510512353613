import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import './styles.scss';
import { avatarimg, beesimg, heroTextBg } from '../../utils/Assets';
import { useStateContext } from '../context/AppContext';

function Banner01() {
  const navigate = useNavigate();
  const { isAuthenticated } = useStateContext();
  const [data, setdata] = useState([]);
  const [data2, setdata2] = useState('');
  const [whitePaper, setwhitePaper] = useState('');

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const openUrl = () => {
    navigate('/governance')
    // console.log('heloo');
    // const fileUrl = `${global.imageURL}/nodeassets/${whitePaper}`;
    // window.open(fileUrl, '_blank');
  };

  useEffect(() => {
    axios
      .get(`${global.backendUrl}/herosection`)
      .then((res) => {
        setdata(res?.data);
      })
      .catch((err) => console.log(err));

    axios
      .get(`${global.backendUrl}/howtovote/get`)
      .then((res) => {
        setdata2(res?.data[0].htv_VideoLink);
      })
      .catch((err) => console.log(err));

    axios
      .get(`${global.imageURL}/whitePaper/get`)
      .then((res) => {
        setwhitePaper(res?.data[0].wp_pdf);
      })
      .catch((err) => console.log(err));
  }, []);
  const handleClick = () => {
    if (isAuthenticated) {
      navigate('/create');
    } else {
      navigate('/login');
    }
  };

  return (
    <section className="tf-slider">
      <div className="tf-container">
        <div className="row">
          <div className="col-md-12">
            <div className="slider-home home1">
              <div className="tf-slider-item">
                <div className="content-inner">
                  <h3 style={{ color: '#3749E9' }}>
                    {data[0]?.hero_subtitle}
                    {/* <span className="color--secondary-color">
                      <i className="ms-2 fa-solid fa-wifi"></i>
                    </span> */}
                  </h3>
                  <h1 className="heading">
                    {/* Defind, Coll<span>ect</span> and Sell Sup<span>er</span>{" "}
                    Rate NFT */}
                    {data[0]?.hero_title}
                    {/* <img src={heroTextBg} alt="ANTS" />  */}
                  </h1>
                  <p className="sub-heading">
                    {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Integer nec odio. Praesent libero. Sed cursus ante dapibus
                    diam. */}
                    {data[0]?.hero_text}
                  </p>
                  <div className="btn-slider  ">
                    <a
                     onClick={handleClick}
                      rel="noreferrer"
                      style={{color:'white'}}
                      // href={`http://${data[0]?.hero_btnUrl}`}
                      className="tf-button style-2 space-y-4"
                    >
                      {data[0]?.hero_btnText}
                    </a>
                    <div onClick={openUrl} className="tf-button style-3">
                      Be part of the Ants governance
                    </div>
                  </div>
                </div>
                <div className="image">
                  <div className="img-slider-main">
                    {/* <img
                      src={
                        "https://ANTSreact.vercel.app/static/media/slider-1.659aee2f403e034a0ec9.jpg"
                      }
                      alt="ANTS"
                    /> */}
                    {/* <img
                      src={`${global.imageURL}/nodeassets/${data[0]?.hero_image}`}
                      alt="Ants Protocol"
                      style={{
                        width: '450px',
                        height: '500px',
                        objectFit: 'cover',
                      }}
                    /> */}
                    <iframe
                      title="iframe"
                      style={{ borderRadius: '19px' }}
                      src={data[0]?.hero_slider}
                      frameborder="0"
                      width="450px"
                      height="500px"
                    ></iframe>
                  </div>
                  {/* <img
                    src={beesimg}
                    alt="ANTS"
                    className="img-slider-2 ani4"
                  />
                  <img
                    src={beesimg}
                    alt="ANTS"
                    className="img-slider-3 ani5"
                  /> */}


                  <Modal show={show} onHide={handleClose}>
                    <div
                      class="modal-header position-absolute w-100"
                      style={{ zIndex: 1, borderBottom: 0 }}
                    >
                      <button
                        type="button"
                        class="btn-close bg-white"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={handleClose}
                      ></button>
                    </div>
                    <div class="modal-body p-0 bg-dark">
                      <iframe
                        src={data2}
                        className="w-100"
                        height="300"
                        title="Iframe Example"
                      ></iframe>
                    </div>
                  </Modal>

                  {/* <div className="card-infor ani5">
                    <img src={avatarimg} alt="ANTS" />
                    <div className="inner ">
                      <h6 className="name">{"“The Monkey sad ”"}</h6>
                      <p className="author">{"@SolvadorDali"}</p>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Banner01;
