import React, { useState, useEffect } from 'react';

import icon1 from '../../assets/images/svg/icon-create-5.svg';
import icon2 from '../../assets/images/svg/icon-create-6.svg';
import icon3 from '../../assets/images/svg/icon-create-3.svg';
import star from '../../assets/images/slider/slider-8.png';

function MarketingSupport() {
    const [expandedItems, setExpandedItems] = useState([]);

    const toggleExpand = (id) => {
        if (expandedItems.includes(id)) {
            setExpandedItems(expandedItems.filter((item) => item !== id));
        } else {
            setExpandedItems([...expandedItems, id]);
        }
    };


    const [dataHelp] = useState([
        {
            id: 1,
            icon: icon1,
            title: 'Pre-launch',
            points: [
                "Announcement Campaign",
                "Marketing Strategy",
                "Video Production",
                "Live AMA Sessions"
            ],
            text: 'Learn how to create an account, set up your wallet, and what you can do on BinaSea',
            link: '#',
        },
        {
            id: 2,
            icon: icon2,
            title: 'Launch',
            points: [
                "Hands-on Support",
                "Allowlist Engagement",
                "Share Launch Results",
                "Campaign Review"
            ],
            text: 'Learn how to create an account, set up your wallet, and what you can do on BinaSea',
            link: '#',
        },
        {
            id: 3,
            icon: icon3,
            title: 'Post-launch',
            points: [
                "Performance Review",
                "Strategy Review",
                "Success Promo Campaign",
                "Live Poolside Podcast"
            ],
            text: 'Learn how to create an account, set up your wallet, and what you can do on BinaSea',
            link: '#',
        },
    ]);
    return (
        <section className="tf-help-center">
            <div className="tf-container">
                <div className="row justify-content-center">
                    <div className="col-lg-12 col-md-12">
                        <div className="tf-heading style-5">
                            <h4 className="heading">Marketing and Community support</h4>
                        </div>
                        <div className="tf-create-wrap justify-content-center">
                            {dataHelp.map((item) => (
                                <div key={item.id} className="tf-create style-2">
                                    <div className="icon">
                                        <img
                                            src={`${item.icon}`}
                                            alt="ANTS"
                                        />
                                    </div>
                                    <h6 className="title">{item.title}</h6>
                                    {item.points ?
                                        <ul className="points-list"> {/* Add a new ul element for the points */}
                                            {item.points.map((point, index) => (
                                                <li key={index}>
                                                    <img
                                                        src={`${star}`}
                                                        alt="Star"
                                                        className="point-icon" // Add a class for styling the icon
                                                    />
                                                    {point}
                                                </li>
                                            ))}
                                        </ul>
                                        : null}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default MarketingSupport;

