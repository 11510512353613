import React, { useEffect, useState } from "react";
import PageTitle from "../components/pagetitle/PageTitle";
import { useLocation } from "react-router-dom";
import { useDashboard } from "../hooks/dashboard";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Web3 from "web3";
import ABIStaking from "../utils/abi-staking";
import { stakingContractAddress } from "../utils/utils";

function ProposalDetailPage() {
  const location = useLocation();
  const initialProposal  = location.state || {};
  const { userUid,voteData,propoalIsExecuted,votesOnPropoal } = useDashboard();
  const navigate = useNavigate();
  const [proposal, setProposal] = useState(initialProposal);
  const [selectedVote, setSelectedVote] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasVoted, setHasVoted] = useState(false);



  useEffect(() => {
    const userVote = voteData.find((vote) => vote.user_id === userUid && vote.proposal_id === proposal.proposal_id);
    if (userVote) {
      setSelectedVote(userVote.vote_value);
      setHasVoted(true);
    } else {
      setSelectedVote(null);
      setHasVoted(false);
    }
  }, [voteData, userUid, proposal.proposal_id]);

  useEffect(() => {
    const fetchProposalData = async () => {
      try {
        const response = await axios.get(`${global.backendUrl}/api/proposal/${proposal.proposal_id}`);
        setProposal(response.data);
      } catch (error) {
        console.error("Error fetching proposal data:", error);
      }
    };

    fetchProposalData();
  }, [userUid, proposal.proposal_id]);
  
useEffect(() => {
  const userVote = voteData.find((vote) => vote.user_id === userUid || vote.proposal_id === proposal.proposal_id);
  if (userVote) {
    setSelectedVote(userVote.vote_value);
    setHasVoted(true);
  }
}, [voteData, userUid, proposal.proposal_id]);


const customStyle = {
  color: 'var(--primary-color5)',  
 
};
const customStyles = {
  color: 'var(--primary-color5)',  
  display:'flex',
  flexDirection:'row',
  paddingRight:"10px"
};
const handleVote = async (voteValue) => {
  if (!userUid) {
    toast.error("Please login to vote");
    return;
  }
  if (hasVoted || isSubmitting) {
    toast.info("You have already voted on this proposal");
    return;
  }

  if (userUid === proposal.proposal_user_id) {
    toast.error("You cannot vote on your own proposal");
    return;
  }

  setIsSubmitting(true);
  try {
    const response = await axios.post(`${global.backendUrl}/api/vote`, {
      user_id: userUid,
      proposal_id: proposal.proposal_id,
      vote_value: voteValue
    });

    if (response.status === 200) {
      setSelectedVote(voteValue);
      setHasVoted(true);
      toast.success(`Vote ${voteValue} submitted successfully`);
    }
  } catch (error) {
    console.error("Vote error:", error.response?.data || error);
    if (error.response?.status === 400) {
      toast.error("You have already voted on this proposal");
      setHasVoted(true);
    } else {
      toast.error("Failed to submit vote");
    }
  } finally {
    setIsSubmitting(false);
  }
  const voteBool = voteValue === "yes";
  try {
    const web3 = new Web3(window.ethereum);
    await window.ethereum.enable();
    const accounts = await web3.eth.getAccounts();
    const account = accounts[0];
    let contract = new web3.eth.Contract(ABIStaking, stakingContractAddress);
    console.log(contract.methods);

    const gasPrice = await web3.eth.getGasPrice();
    console.log("gasPrice", gasPrice);

    await  contract.methods
    .vote(proposal.proposal_id, voteBool) 
    .send({
        from: account,
        gas: 2000000,
        gasPrice: gasPrice,
        value: 0,
      })
      .then((result) => {
        console.log("Transaction successful:", result);
      })
      .catch((error) => {
        console.error("Transaction failed in vote:", error);
      });
  } catch (error) {
    console.error("Error in blockchain interaction:", error);
  }
};


  const renderVotingButtons = () => {
    const isProposalEnded = new Date(proposal.proposal_end) < new Date();
    const voteCount = votesOnPropoal.find(
      (item) => item.proposal_id === proposal.proposal_id
    )?.vote_count || 0;
    const quorumToken = propoalIsExecuted?.[0]?.voting_quorum || 0;

    const proposalIsExecuted = votesOnPropoal.find(
      (item) => item.proposal_id === proposal.proposal_id
    )?.proposal_is_executed === "yes";
  
    const showExecutedButton = voteCount >= quorumToken && isProposalEnded;

 
    const handleExecuteProposal = async () => {
      try {
        const response = await axios.put(
          `${global.backendUrl}/proposalEdit/${proposal.proposal_id}`,
          {
            ...proposal,
            proposal_is_executed: "yes",
          }
        );
        if (response.status === 200) {
          toast.success("Proposal executed successfully!");
          navigate(0);  
        }
      } catch (error) {
        console.error("Execution error:", error.response?.data || error);
        toast.error("Failed to execute proposal");
      }

      // transaction
      try {
        const web3 = new Web3(window.ethereum);
        await window.ethereum.enable();
        const accounts = await web3.eth.getAccounts();
        const account = accounts[0];
        let contract = new web3.eth.Contract(ABIStaking, stakingContractAddress);
        console.log(contract.methods);
    
        const gasPrice = await web3.eth.getGasPrice();
        console.log("gasPrice", gasPrice);
    
        await  contract.methods
        .executeProposal(proposal.dataObj.staking_id) 
        .send({
            from: account,
            gas: 2000000,
            gasPrice: gasPrice,
            value: 0,
          })
          .then((result) => {
            console.log("Transaction successful:", result);
          })
          .catch((error) => {
            console.error("Transaction failed:", error);
          });
      } catch (error) {
        console.error("Error in blockchain interaction:", error);
      }
    };


  

    if (userUid !== proposal.proposal_user_id) {
      return (
        <>
          <div >
            <p style={customStyle}>Vote on proposal</p>
          </div>
          <div className="button voting-buttons" >
          <div style={{display:'flex'}}>
          <button
              className={`tf-button vote-button ${
                selectedVote === "yes" ? "active bg-green-500" : ""
              }`}
              onClick={() => handleVote("yes")}
              disabled={hasVoted || isSubmitting}
              style={{ 
                cursor: hasVoted || isSubmitting ? 'not-allowed' : 'pointer',
                opacity: hasVoted && selectedVote !== "yes" ? 0.5 : 1,
              }}
            >
              Yes
              {selectedVote === "yes" && " ✓"}
            </button>
            <button
              className={`tf-button vote-button ${
                selectedVote === "no" ? "active bg-red-500" : ""
              }`}
              onClick={() => handleVote("no")}
              disabled={hasVoted || isSubmitting}
              style={{ 
                cursor: hasVoted || isSubmitting ? 'not-allowed' : 'pointer',
                opacity: hasVoted && selectedVote !== "no" ? 0.5 : 1,
              }}
            >
              No
              {selectedVote === "no" && " ✓"}
            </button>
          </div>
            {showExecutedButton && 
            <button style={{display:'block',marginTop:'20px'}}
              className={`tf-button vote-button ${proposalIsExecuted ?'active':''}`}
              onClick={handleExecuteProposal}
              disabled={proposalIsExecuted}
            >
              {proposalIsExecuted?'Executed':'Execute'}
            </button>
    }
          </div>
         
        </>
      );
    } else {
      return (
        <div className="button voting-buttons">
          <button
            className="tf-button"
            onClick={() =>
              navigate("/createProposal", {
                state: { dataObj: proposal },
              })
            }
          >
            Edit
          </button>
        </div>
      );
    }
  };

 

  return (
    <div>
      <PageTitle sub="Project" title="Proposal Details" />
      <div className="topMain">
        <div className="tf-item-detail-inner ss">
          <div className="content">
            <div className="main">
              <div className="first">
                <div style={customStyle}>
                <h4   style={customStyle} >{proposal?.proposal_title}</h4>
                </div>
                <div style={customStyles}>
                  <p style={customStyle}>Start:</p>
                 <p style={{paddingTop:'20px',paddingLeft:'20px'}}> 
                  
                   {proposal?.proposal_start}
                  </p> 
                </div>
                <div style={customStyles}>
                  <p style={customStyle}>End:</p> <p style={{paddingTop:'20px',paddingLeft:'20px'}}>
                  {proposal?.proposal_end}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="tf-item-detail-inner ss">
          <div className="content">
            <div className="main">
              <div className="second">
                <div style={customStyle}>
                  <p style={customStyle}>Description:</p> <br />
                  {proposal?.proposal_description}
                </div>
                <div className="content-bottom proposal-voting">
                  {renderVotingButtons()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProposalDetailPage;