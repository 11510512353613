import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import menus from "../../pages/menu";
import { Dropdown } from "react-bootstrap";
import { useStateContext } from "../context/AppContext";
import "./styles.scss";
import logo from "../../assets/images/logo/logo.png";
import logodark from "../../assets/images/logo/logo_dark.png";
import "../../css/Header.css";

import DarkMode from "./DarkMode";
// import Button from '../button';
// deployment
const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isAuthenticated, setisAuthenticated } = useStateContext();
  const [scroll, setScroll] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);

  const [headerImage, setheaderImage] = useState("");
  const [popup, setpopup] = useState(false);
  const [menuActive, setMenuActive] = useState(null);

  const [userImg, setuserImg] = useState("");
  const menuRef = useRef(null);
  const mobileButtonRef = useRef(null);
  const profileMenuRef = useRef(null);

  const dummyImage =
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQD116U9ZCk8bEaanCeB5rSCC2uqY5Ka_2_EA&usqp=CAU";

  const handleLogout = () => {
    localStorage.removeItem("antsInformation");
    setisAuthenticated(false);
    setpopup(false);
    setuserImg("");
    navigate("/");
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Close mobile menu if clicked outside
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        mobileButtonRef.current &&
        !mobileButtonRef.current.contains(event.target)
      ) {
        setMenuActive(false);
      }

      // Close profile popup if clicked outside
      if (
        profileMenuRef.current &&
        !profileMenuRef.current.contains(event.target)
      ) {
        setpopup(false);
      }
    };

    axios
      .get(`${global.backendUrl}/antsbrand`)
      .then((response) => {
        setheaderImage(response.data[0]?.antsbrand_header_img);
      })
      .catch((err) => {
        console.log(err);
      });

    const AccountInfo = localStorage.getItem("antsInformation");
    const parsedInfo = JSON.parse(AccountInfo);

    axios
      .get(`${global.backendUrl}/signup/get`)
      .then((response) => {
        const filteredData = response.data.filter(
          (t) => t.uid == parsedInfo?.uid
        );
        setuserImg(filteredData[0]?.image);
      })
      .catch((err) => {
        console.log(err);
      });

    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 300);
    });
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleMenuActive = () => {
    setMenuActive(!menuActive);
  };

  const handleLogoClick = (e) => {
    if (location.pathname === "/") {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    } else {
      navigate("/");
    }
  };

  const handleDropdown = (index) => {
    // Only close the menu if the clicked menu item doesn't have submenu
    const selectedMenu = menus[index];
    if (!selectedMenu.namesub) {
      setMenuActive(false);
    }

    // Toggle active index
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <>
      <header className={`header ${scroll ? "is-fixed" : ""}`}>
        <div className="tf-container">
          <div className="row">
            <div className="col-md-12">
              <div id="site-header-inner">
                <div id="site-logo" className="clearfix">
                  <div id="site-logo-inner" onClick={handleLogoClick}>
                    <Link to="/" rel="home" className="main-logo">
                      <img
                        id="logo_header"
                        className="logo-dark"
                        style={{
                          height: "48px",
                          objectFit: "cover",
                        }}
                        src={`${global.imageURL}/nodeassets/${headerImage}`}
                        alt="Ants Protocol"
                      />
                      <img
                        id="logo_header"
                        className="logo-light"
                        style={{
                          height: "48px",
                          objectFit: "cover",
                          filter: "invert(1)",
                        }}
                        src={`${global.imageURL}/nodeassets/${headerImage}`}
                        alt="Ants Protocol"
                      />
                    </Link>
                  </div>
                </div>
                <div className="header-center">
                  <nav
                    ref={menuRef}
                    id="main-nav"
                    className={`main-nav ${menuActive ? "active" : ""}`}
                  >
                    <ul id="menu-primary-menu" className="menu">
                      {menus.map((data, idx) => (
                        <li
                          key={idx}
                          onClick={() => handleDropdown(idx)}
                          className={`menu-item ${
                            data.namesub ? "menu-item-has-children" : ""
                          } ${activeIndex === idx ? "active" : ""}`}
                        >
                          <Link to={data.links}>{data.name}</Link>
                          {data.namesub && (
                            <ul className="sub-menu">
                              {data.namesub.map((submenu) => (
                                <li key={submenu.id} className="menu-item">
                                  <NavLink to={submenu.links}>
                                    {submenu.sub}
                                  </NavLink>
                                </li>
                              ))}
                            </ul>
                          )}
                        </li>
                      ))}
                      {!isAuthenticated && menuActive && (
                        <li onClick={handleMenuActive}>
                          <Link
                            to="/login"
                            className="tf-button"
                            style={{
                              margin: "0 20px",
                              padding: "10px",
                              textAlign: "center",
                              display: "block",
                            }}
                          >
                            Login
                          </Link>
                        </li>
                      )}
                    </ul>
                  </nav>
                </div>

                <div className="header-right">
                  <div>
                    {isAuthenticated ? (
                      <div
                        ref={profileMenuRef}
                        style={{
                          position: "relative",
                          marginRight: "5px",
                          cursor: "pointer",
                        }}
                      >
                        <img
                          src={
                            userImg
                              ? `${global.imageURL}/nodeassets/${userImg}`
                              : dummyImage
                          }
                          style={{
                            height: "40px",
                            width: "40px",
                            borderRadius: "50%",
                          }}
                          onClick={() => setpopup(!popup)}
                        />

                        {popup && (
                          <div className="Profile_menu">
                            <div className="Profile_menu_list">
                              <li
                                className="Profilemenu_item"
                                onClick={() => {
                                  navigate("/dashboard", {
                                    state: { tabIndex: 0 },
                                  });
                                  setpopup(false);
                                }}
                              >
                                Dashboard
                              </li>
                              <li
                                className="Profilemenu_item"
                                onClick={() => {
                                  navigate("/dashboard", {
                                    state: { tabIndex: 4 },
                                  });
                                  setpopup(false);
                                }}
                              >
                                Notifications
                              </li>
                              <li
                                className="Profilemenu_item"
                                onClick={() => {
                                  navigate("/create");
                                  setpopup(false);
                                }}
                              >
                                Create Project
                              </li>
                              <li
                                className="Profilemenu_item"
                                onClick={handleLogout}
                              >
                                Logout
                              </li>
                            </div>
                          </div>
                        )}
                      </div>
                    ) : (
                      <Link
                        to="/login"
                        style={{
                          height: 40,
                          paddingTop: 6,
                          marginTop: 5,
                          textAlign: "center",
                          alignItems: "center",
                        }}
                        className="tf-button cursor-pointer"
                      >
                        <span>Login</span>
                      </Link>
                    )}
                  </div>
                  <div>
                    <DarkMode />
                  </div>
                </div>

                <div
                  ref={mobileButtonRef}
                  className={`mobile-button ${menuActive ? "active" : ""}`}
                  onClick={handleMenuActive}
                >
                  <span></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      {/* <header className={`header ${scroll ? 'd-flex' : 'd-none is-fixed'}`}>
        <div className="tf-container">
          <div className="row">
            <div className="col-md-12">
              <div id="site-header-inner">
                <div id="site-logo" className="clearfix">
                  <div id="site-logo-inner" onClick={handleLogoClick} >
                    <Link to="/" rel="home" className="main-logo">
                      
                      <img
                        id="logo_header"
                        className="logo-dark"
                        style={{
                          height: '48px',
                          objectFit: 'cover',
                        }}
                        src={`${global.imageURL}/nodeassets/${headerImage}`}
                        alt="Ants Protocol"
                      />
                      <img
                        id="logo_header"
                        className="logo-light"
                        style={{
                          height: '48px',
                          objectFit: 'cover',
                          filter: 'invert(1)',
                        }}
                        src={`${global.imageURL}/nodeassets/${headerImage}`}
                        alt="Ants Protocol"
                      />
                    </Link>
                  </div>
                </div>

                <div className="header-center" style={{position:'fixed'}}>
                  <nav
                    id="main-nav"
                    className={`main-nav ${menuActive ? 'active' : ''}`}
                  >
                    <ul id="menu-primary-menu" className="menu">
                      {menus.map((data, idx) => (
                        <li
                          key={idx}
                          onClick={() => handleDropdown(idx)}
                          className={`menu-item ${data.namesub ? 'menu-item-has-children' : ''
                            } ${activeIndex === idx ? 'active' : ''}`}
                        >
                          <Link to={data.links}>{data.name}</Link>
                          {data.namesub && (
                            <ul className="sub-menu">
                              {data.namesub.map((submenu) => (
                                <li key={submenu.id} className="menu-item">
                                  <NavLink to={submenu.links}>
                                    {submenu.sub}
                                  </NavLink>
                                </li>
                              ))}
                          
                            </ul>
                          )}
                        </li>
                      ))}
                      
                       
                    </ul>
                  </nav>
                </div>

                <div className="header-right">
                  {isAuthenticated ? (
                    <div style={{ position: 'relative', marginRight: '15px' }}>
                      <img
                        src={
                          userImg
                            ? `${global.imageURL}/nodeassets/${userImg}`
                            : dummyImage
                        }
                        style={{
                          height: '40px',
                          width: '40px',
                          borderRadius: '50%',
                        }}
                        onClick={() => setpopup(!popup)}
                      />

                      {popup && (
                        <div className="Profile_menu">
                          <div className="Profile_menu_list">
                            <li
                              className="Profilemenu_item"
                              onClick={() => {
                                navigate('/dashboard');
                                setpopup(false);
                              }}
                            >
                              Dashboard
                            </li>
                            <li
                              className="Profilemenu_item"
                              onClick={() => {
                                navigate('/create');
                                setpopup(false);
                              }}
                            >
                              Create Project
                            </li>
                            <li
                              className="Profilemenu_item"
                              onClick={handleLogout}
                            >
                              Logout
                            </li>
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <Link to="/login" style={{ height: 40, paddingTop: 6, marginTop: 5, justifyContent: "center", textAlign: "center", alignItems: "center" }} className="tf-button  ">
                      <span>Login</span>
                    </Link>
                  )}
                  <DarkMode />
                </div>

                <div
                  className={`mobile-button ${menuActive ? 'active' : ''}`}
                  onClick={handleMenuActive}
                >
                  <span></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header> */}
    </>
  );
};

export default Header;
