import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import contibution from '../../assets/images/svg/contribution.svg';
import projects from '../../assets/images/svg/projects.svg';
import projects1 from '../../assets/images/svg/recipient.svg';
import stacked from '../../assets/images/svg/stacked.svg';


function CountingLaunchPage() {
  return (
    <section className="tf-section tf-create-and-sell">
      <div className="tf-container">
        <div className="row">
          <div className="col-lg-3 col-md-6">
            <div className="tf-create wow fadeInUp border-gray" data-wow-delay="0.2s">
              <div
                style={{
                  display: 'flex',
                  gap: '5px',
                  justifyContent: 'center',
                }}
              >
                <div>
                  <div className="icon">
                    <img src={contibution} alt="ANTS" />
                  </div>
                </div>
                <h6 className="title ">
                  <Link to="#">Total Contributions</Link>
                </h6>
              </div>
              <p
                style={{ fontSize: '30px', marginTop: '10px' }}
              >
                $9M
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="tf-create wow fadeInUp border-gray" data-wow-delay="0.2s">
              <div
                style={{
                  display: 'flex',
                  gap: '5px',
                  justifyContent: 'center',
                }}
              >
                <div>
                  <div className="icon">
                    <img src={projects} alt="ANTS" />
                  </div>
                </div>
                <h6 className="title pt-4">
                  <Link to="#">Active Projects</Link>
                </h6>
              </div>
              <p
                style={{ fontSize: '30px', marginTop: '10px' }}
              >
                137
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="tf-create wow fadeInUp border-gray" data-wow-delay="0.2s">
              <div
                style={{
                  display: 'flex',
                  gap: '5px',
                  justifyContent: 'center',
                }}
              >
                <div>
                  <div className="icon">
                    <img src={projects1} alt="ANTS" />
                  </div>
                </div>
                <h6 className="title">
                  <Link to="#">MintBack Recipients</Link>
                </h6>
              </div>
              <p
                style={{ fontSize: '30px', marginTop: '10px' }}
              >
                90
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="tf-create wow fadeInUp border-gray" data-wow-delay="0.2s">
              <div
                style={{
                  display: 'flex',
                  gap: '5px',
                  justifyContent: 'center',
                }}
              >
                <div>
                  <div className="icon">
                    <img src={stacked} alt="ANTS" />
                  </div>
                </div>
                <h6 className="title pt-4">
                  <Link to="#">Stacked ANTS</Link>
                </h6>
              </div>
              <p
                style={{ fontSize: '30px', marginTop: '10px' }}
              >
                100,000
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CountingLaunchPage;

