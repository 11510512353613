import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import { Modal } from "react-bootstrap";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import ABI from '../../utils/abi';
import { blockchainTxnAddress, contractAddress } from '../../utils/utils';
import Web3 from 'web3';

const ProfitableModal = (props) => {
    const [amount, setamount] = useState()
    const [isLoading, setisLoading] = useState(false)
    const [data, setdata] = useState([])
    const [data2, setdata2] = useState([])
    const [investmentSendTemplate, setInvestmentSendTemplate] = useState([])
    const [investmentReceiveTemplate, setInvestmentReceiveTemplate] = useState([])
    const [etherPrice, setEtherPrice] = useState(3085.24);

    const day = new Date().getDate();
    const month = new Date().getMonth() + 1;
    const year = new Date().getFullYear();

    const date = month + "/" + day + "/" + year;

    const [purchasedTickets, setPurchasedTickets] = useState([]);
    useEffect(() => {
        if (!props.show) {
            setamount('');
            setisLoading(false);
        }
    }, [props.show]);
    useEffect(() => {
        const AccountInfo = localStorage.getItem('antsInformation');
        const parsedInfo = JSON.parse(AccountInfo);
        if (parsedInfo) {
            fetchPurchasedTickets(parsedInfo)
        }
    }, [])

    const fetchPurchasedTickets = (parsedInfo) => {
        axios
            .get(`${global.backendUrl}/tickets/purchase/${parsedInfo?.uid}`)
            .then((res) => {
                setPurchasedTickets(res.data)
            })
            .catch((err) => console.log(err));
    }

    function getDollarValues() {
        axios
            .get(
                `https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=bitcoin%2Cethereum&order=market_cap_desc  &per_page=100&page=1&sparkline=false`
            )
            .then((response) => {
                setEtherPrice(response.data[1].current_price);
            })
            .catch((error) => console.log(error));
    }
    const handleContinue = async () => {
        console.log("purchasedTickets", purchasedTickets)
        console.log("amount", amount)
        const AccountInfo = localStorage.getItem('antsInformation');
        const parsedInfo = JSON.parse(AccountInfo);
        let tokensToAssign = "0"
        if (purchasedTickets.length > 0) {
            const ticketReturnValue = purchasedTickets[0].ticket_return
            const returnAmount = parseFloat(amount) * (parseFloat(ticketReturnValue) / 100);
            console.log("returnAmount", returnAmount)
            const weightage = purchasedTickets[0].ticket_weightage
            tokensToAssign = parseInt(Math.round(returnAmount / weightage));
        }
        if (props.wallet_address) {
            if (props.wallet_address == "") {
                toast.error("Project owner doesn't have any associated wallet!", {
                    position: toast.POSITION.TOP_RIGHT,
                });
                return
            }
        } else {
            toast.error("Project owner doesn't have any associated wallet!", {
                position: toast.POSITION.TOP_RIGHT,
            });
            return
        }
        if (!props.userId) { return showError("login again! There is an error") };
        if (!amount) { return showError("enter the amount") };



        function showError(fieldName) {
            toast.error(`Please ${fieldName}`, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

        const lessAmount = props?.amountWantRaise - data


        if (amount > lessAmount) {
            return toast.error(`You can only donate ${lessAmount}$`, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

        const userId = parsedInfo.uid;
    const affiliateId_user = parsedInfo.affiliate_id || null;

    let affiliateAmount = 0;
    let remainingAmount = parseFloat(amount);

    if (affiliateId_user) {
        try {
            const affiliateMarginRes = await axios.get(`${global.backendUrl}/api/affiliate_margin/get`);
          
            const affiliateMarginData = affiliateMarginRes.data.data;
            if(affiliateMarginData && affiliateMarginData.margin){

            const affiliateMargin = parseFloat(affiliateMarginData.margin);

            affiliateAmount = (affiliateMargin / 100) * parseFloat(amount);
            remainingAmount = parseFloat(amount) - affiliateAmount;

     

            const affiliateData = {
                userId: userId,
                affiliateId: affiliateId_user,
                project_id: props.projectId,
                value: affiliateAmount,
                project_type: 'invest',
            };
            await axios.post(`${global.backendUrl}/affiliates_donate/insert`, affiliateData);
        }

        } catch (error) {
            console.error("Error fetching affiliate margin:", error);
            return showError("fetching affiliate margin");
        }
    }


        const filter = data2?.filter((t) => (t.uid == props.userId) && (t.project_id == props.projectId))
        axios.get(`${global.backendUrl}/walletassocaite`)
            .then(async (resAssociation) => {
                if (resAssociation.data.length < 1) {
                    toast.error("Enter amount greater than zero!", {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    return
                }
                let wallets = [], percentages = [];
                resAssociation.data.forEach(element => {
                    wallets.push(element.wallet_address)
                    percentages.push(parseFloat(element.wallet_percentage))
                });
                console.log("wallets", wallets)
                console.log("percentages", percentages)
                const dollarToEther = parseFloat(remainingAmount) / etherPrice;

                const web3 = new Web3(window.ethereum);
                await window.ethereum.enable();
                // Get the current account
                const accounts = await web3.eth.getAccounts();
                const account = accounts[0];
                let owner = account;
                console.log("dollarToEther", dollarToEther)
                console.log("dollarToEther", dollarToEther.toFixed(14))
                const dollarToEtherString = dollarToEther.toFixed(14);
                console.log("dollarToEtherString", dollarToEtherString)
                let weiValue = 0;
                weiValue = Web3.utils.toWei(dollarToEtherString, "ether");
                console.log("weiValue", weiValue)
                let contract = new web3.eth.Contract(ABI, contractAddress);
                console.log(contract.methods);
                const gasPrice = await web3.eth.getGasPrice();
                console.log("gasPrice", gasPrice)
                try {
                    contract.methods
                        .investProject(props.wallet_address, wallets, percentages, parsedInfo.wallet_address, tokensToAssign)
                        .send({
                            from: owner,
                            gas: 2000000,
                            gasPrice: gasPrice,
                            value: weiValue
                        })
                        .then(async function (result) {
                            console.log("Transaction:", result)
                            toast.success(`You donated successfully`, {
                                onClose: () => props.onHide()
                            });
                            props.onHide();

                            if (filter.length == 0) {
                                setisLoading(true)
                                const formData = { project_id: props.projectId, uid: props.userId, donation_amount: remainingAmount, donation_type: props.donationType, donation_date: date }
                                axios.post(`${global.backendUrl}/donation/insert`, formData)
                                    .then((response) => {
                                        if (props.user_email && investmentSendTemplate.length > 0) {
                                            let templateString = investmentSendTemplate[0].email_template_content;
                                            let finalTemplate = templateString
                                                .replace("@investment_amount", "$" + remainingAmount)
                                                .replace("@project_name", props.projectName)
                                                .replace("@txn_hash", blockchainTxnAddress + "/" + result.transactionHash);
                                            console.log("finalTemplate", finalTemplate)
                                            const formDataEmail = {
                                                user_email: props.user_email,
                                                subject: investmentSendTemplate[0].email_template_subject,
                                                message: finalTemplate
                                            }
                                            axios.post(`${global.backendUrl}/sendEmail`, formDataEmail)
                                                .then((res) => {
                                                    const AccountInfo = localStorage.getItem('antsInformation');
                                                    const parsedInfo = JSON.parse(AccountInfo);
                                                    let email = parsedInfo?.email;
                                                    if (email && investmentReceiveTemplate.length > 0) {
                                                        let templateStringReceive = investmentReceiveTemplate[0].email_template_content;
                                                        let finalTemplateReceive = templateStringReceive
                                                            .replace("@investment_amount", "$" + remainingAmount)
                                                            .replace("@project_name", props.projectName)
                                                            .replace("@txn_hash", blockchainTxnAddress + "/" + result.transactionHash);
                                                        const formDataEmail = {
                                                            user_email: email,
                                                            subject: investmentReceiveTemplate[0].email_template_subject,
                                                            message: finalTemplateReceive
                                                        }
                                                        axios.post(`${global.backendUrl}/sendEmail`, formDataEmail)
                                                            .then((res) => {
                                                                toast.success(`Investment Sent Successfully`, {
                                                                    position: "top-right",
                                                                    autoClose: 3000,
                                                                    hideProgressBar: false,
                                                                    closeOnClick: true,
                                                                    pauseOnHover: true,
                                                                    draggable: true,
                                                                    progress: undefined,
                                                                });
                                                                setamount("")
                                                                setisLoading(false)
                                                                props.onHide()
                                                            })
                                                            .catch((error) => {
                                                                console.log(error);
                                                            });
                                                    }

                                                })
                                                .catch((error) => {
                                                    console.log(error);
                                                });
                                        }
                                    })
                                    .catch((error) => {
                                        console.log(error)
                                        setisLoading(false)
                                    })
                            } else {
                                setisLoading(true)
                                const newAmount = parseInt(remainingAmount) + parseInt(filter[0].donation_amount)
                                const formData = { donation_amount: newAmount, donation_type: props.donationType, donation_date: date }
                                axios.put(`${global.backendUrl}/donation/update/${filter[0].donation_id}`, formData)
                                    .then((response) => {
                                        if (props.user_email && investmentSendTemplate.length > 0) {
                                            let templateString = investmentSendTemplate[0].email_template_content;
                                            let finalTemplate = templateString
                                                .replace("@investment_amount", "$" + remainingAmount)
                                                .replace("@project_name", props.projectName)
                                                .replace("@txn_hash", blockchainTxnAddress + "/" + result.transactionHash);
                                            console.log("finalTemplate", finalTemplate)
                                            const formDataEmail = {
                                                user_email: props.user_email,
                                                subject: investmentSendTemplate[0].email_template_subject,
                                                message: finalTemplate
                                            }
                                            axios.post(`${global.backendUrl}/sendEmail`, formDataEmail)
                                                .then((res) => {
                                                    const AccountInfo = localStorage.getItem('antsInformation');
                                                    const parsedInfo = JSON.parse(AccountInfo);
                                                    let email = parsedInfo?.email;
                                                    if (email && investmentReceiveTemplate.length > 0) {
                                                        let templateStringReceive = investmentReceiveTemplate[0].email_template_content;
                                                        let finalTemplateReceive = templateStringReceive
                                                            .replace("@investment_amount", "$" + remainingAmount)
                                                            .replace("@project_name", props.projectName)
                                                            .replace("@txn_hash", blockchainTxnAddress + "/" + result.transactionHash);
                                                        console.log("finalTemplateReceive", finalTemplateReceive)
                                                        const formDataEmail = {
                                                            user_email: email,
                                                            subject: investmentReceiveTemplate[0].email_template_subject,
                                                            message: finalTemplateReceive
                                                        }
                                                        axios.post(`${global.backendUrl}/sendEmail`, formDataEmail)
                                                            .then((res) => {
                                                                toast.success(`Investment Sent Successfully`, {
                                                                    position: "top-right",
                                                                    autoClose: 3000,
                                                                    hideProgressBar: false,
                                                                    closeOnClick: true,
                                                                    pauseOnHover: true,
                                                                    draggable: true,
                                                                    progress: undefined,
                                                                });
                                                                setamount("")
                                                                setisLoading(false)
                                                                props.onHide()
                                                            })
                                                            .catch((error) => {
                                                                console.log(error);
                                                            });
                                                    }

                                                })
                                                .catch((error) => {
                                                    console.log(error);
                                                });
                                        }
                                    })
                                    .catch((error) => {
                                        console.log(error)
                                        setisLoading(false)
                                    })
                            }
                        })
                        .then(function (error) {
                            console.log(error);
                        })
                        .catch((error) => console.log(error));
                } catch (error) {
                    console.error('Error fetching data:', error);
                    return { data: {} };  
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const fetchData = () => {
        axios.get(`${global.backendUrl}/donation/get`)
            .then((res) => {
                setdata2(res.data)
                const filter1 = res.data.filter((t) => t.project_id == props.projectId)
                const totalDonation = filter1.reduce((total, item) => total + parseInt(item.donation_amount, 10), 0);
                setdata(totalDonation)
            })
            .catch((error) => {
                console.log(error);
            });

        axios.get(`${global.backendUrl}/email_templates`)
            .then((res) => {
                // console.log("RES", res)
                const sentTemplates = res.data.filter((item) => item.email_template_type === "Investment Send")
                setInvestmentSendTemplate(sentTemplates)

                const receiveTemplates = res.data.filter((item) => item.email_template_type === "Investment Received")
                setInvestmentReceiveTemplate(receiveTemplates)
            })
            .catch((error) => {
                console.log(error);
            });
    };

    useEffect(() => {
        fetchData();
        getDollarValues();
    }, [props]);

    const handleAmountChange = (e) => {
        const inputValue = parseFloat(e.target.value);
        if (inputValue < 0) {
            setamount(0);
        } else {
            setamount(inputValue);
        }
    };

    return (

        <Modal
            show={props.show}
            onHide={props.onHide}
        >
            <Modal.Header closeButton></Modal.Header>
            <div className="modal-body space-y-20 pd-40">
                <h3>Donate Here</h3>
                <p className="text-center sub-heading">{data}$ / <span className="price color-popup">{props?.amountWantRaise}$</span></p>
                <input type="number" value={amount} onChange={handleAmountChange} className="form-control mb-5" placeholder="Enter amount here in dollar" />
                {
                    data >= props?.amountWantRaise ?
                        <button className="button-popup w-100 bg-danger">Fully Funded</button>
                        :
                        <button className="button-popup w-100" disabled={isLoading} onClick={handleContinue}>Continue</button>
                }
            </div>
        </Modal>

    );
};

export default ProfitableModal;
