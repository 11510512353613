import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { editproposal, getproposal, insertproposal,getSignupUsers,getNotification,getPrivateSale, getVoteData, getPropoalIsExecuted,getVotesOnPropoal } from "../api/Api";
import { toast } from "react-toastify";
import Web3 from "web3";
import ABIStaking from '../utils/abi-staking'
import { stakingContractAddress } from "../utils/utils";
import CreateProposal from "../pages/CreateProposal";
export const useDashboard = () => {
  const [title, setTitle] = useState("");
  const [dec, setDec] = useState("");
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [voteData , setVoteData] = useState([]);
  const [userUid, setUserUid] = useState("");
  const [dataAPI, setDataAPI] = useState([]);
  const [saleAPI, setSaleAPI] = useState([]);
  const [userAPI, setuserAPI] = useState([]);
  const [propoalIsExecuted, setPropoalIsExecuted] = useState([]);
  const [votesOnPropoal, setVotesOnPropoal] = useState([]);

  
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(()=>{

    const AccountInfo = localStorage.getItem("antsInformation");
    const parsedInfo = JSON.parse(AccountInfo);
    if(parsedInfo){
      setUserUid(parsedInfo.uid);
    }
  },[])


// create proposal
const handleAdd = async () => {
  if (!title || !dec || !start || !end) {
    toast.error("Please enter all fields");
    return;
  }

  const startTimeInSeconds = Math.floor(new Date(start).getTime() / 1000);
  const endTimeInSeconds = Math.floor(new Date(end).getTime() / 1000);
  
  const formData = {
    proposal_title: title,
    proposal_description: dec,
    proposal_start: start,
    proposal_end: end,
    proposal_created: "user",
    proposal_user_id: userUid,
  };


  try {
    const res = await insertproposal(formData);
 
     if (res.status === 200) {
       const {proposal_id} = res.data;   

 
       const web3 = new Web3(window.ethereum);
       await window.ethereum.enable();
       const accounts = await web3.eth.getAccounts();
       const account = accounts[0];
       const contract = new web3.eth.Contract(ABIStaking, stakingContractAddress);
 
       const gasPrice = await web3.eth.getGasPrice();
       console.log("gasPrice", gasPrice);
 
       await contract.methods
         .createProposal( proposal_id,title, dec, startTimeInSeconds,endTimeInSeconds )  
         .send({
           from: account,
           gas: 2000000,
           gasPrice: gasPrice,
           value: 0,
         })
         .then((result) => {
           console.log("Transaction successful:", result);
       navigate(-1);
         })
         .catch((error) => {
           console.error("Transaction failed:", error);
         });
     } else {
       toast.error("Error occurred in adding data");
     }
   } catch (error) {
     console.error("Error in handleAdd:", error);
     toast.error("An unexpected error occurred");
   }
 };

  // update proposal
  const handleUpdate = async (id) => {
    const formData = {
      proposal_title: title,
      proposal_description: dec,
      proposal_start: start,
      proposal_end: end,
      proposal_created: "user",
    };

    const res = await editproposal(id, formData);
    if (res.status === 200) {
      navigate(-1);
    } else {
      toast.error("Error occure in updating data");
    }
  };

  const populateData = () => {
    if (location?.state?.dataObj) {
      const {
        proposal_title,
        proposal_description,
        proposal_start,
        proposal_end,
        proposal_created,
      } = location.state.dataObj;
      setTitle(proposal_title);
      setDec(proposal_description);
      setStart(proposal_start);
      setEnd(proposal_end);
    }
  };


  // get proposal
  const getData = async () => {
    const res = await getproposal();
    setDataAPI([...res.data]);
  };

// get proposalIsExecuted
const getProposalExecuted = async () => {
  const res = await getPropoalIsExecuted();
  setPropoalIsExecuted([...res.data]);
};
// get votes on proposal
const getvoteonProposal = async () => {
  const res = await getVotesOnPropoal();
  setVotesOnPropoal([...res.data.proposals]);
};

  // get vote data
  const getAllvoteData = async () => {
    const res = await getVoteData();
    setVoteData([...res.data]);
  };

    // get private sale
    const getSaleData = async () => {
      const res = await getPrivateSale();
      setSaleAPI([...res.data]);
    };
  //update
  const populateDatas = () => {
    if (location?.state?.dataObj) {
     const {proposal_title,proposal_description,proposal_start,proposal_end,proposal_created}=location.state.dataObj;
     setTitle(proposal_title)
      setDec(proposal_description)
      setStart(proposal_start)
      setEnd(proposal_end)
    }
  };
  
  // get proposal
  const [notificationAPI, setnotificationAPI] = useState([]);

  const getNotifications = async () => {
    const res = await getNotification();
    setnotificationAPI([...res.data]);
  };

    // get users

    const getusers = async () => {
      const res = await getSignupUsers();
      setuserAPI([...res.data]);
    };

  useEffect(() => {
    populateData();
    populateDatas();
    getData()
    getNotifications()
    getusers()
    getSaleData()
    getAllvoteData()
    getProposalExecuted()
    getvoteonProposal()
  }, []);


  const filteredNotifications = notificationAPI.filter(
    (notifi) => notifi.notification_user_id === userUid
  );
  return {
    title,
    setTitle,
    dec,
    setDec,
    start,
    setStart,
    end,
    setEnd,
    // setTotal,
    // created,
    handleAdd,
    handleUpdate,
    getData,
    dataAPI,
    userUid,
    notificationAPI,
    filteredNotifications,
    saleAPI,
    voteData,
    propoalIsExecuted,
    votesOnPropoal
  };
};
