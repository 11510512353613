import React, { useState, useEffect } from 'react';
import PageTitle from '../components/pagetitle/PageTitle';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Link } from 'react-router-dom';
import axios from 'axios';
import ico1 from '../assets/images/icon/rain1.svg';
import ico2 from '../assets/images/icon/rain2.svg';
import ico3 from '../assets/images/icon/ethe.svg';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import '../css/create.css';
import {
  bloguser,
  coinbasewallet,
  drapupload,
  fortmaticwallet,
  metamaskwallet,
  toruswallet,
} from '../utils/Assets';
import Web3 from 'web3';
import ABI from '../utils/abi';
import { contractAddress } from '../utils/utils';
import { Helmet } from 'react-helmet';

Create.propTypes = {};

function Create(props) {
  const navigate = useNavigate();

  const [emailTemplate, setEmailTemplate] = useState([])

  const [otherCategory, setotherCategory] = useState(false);

  const [userName, setuserName] = useState('');
  const [facebook, setfacebook] = useState('');
  const [linkedin, setlinkedin] = useState('');
  const [discord, setdiscord] = useState('');
  const [youtube, setyoutube] = useState('');
  const [projectName, setprojectName] = useState('');
  const [projectType, setprojectType] = useState('');
  const [projectCategory, setprojectCategory] = useState('');
  const [otherCate, setotherCate] = useState('');
  const [websiteURL, setwebsiteURL] = useState('');
  const [androidAppURL, setandroidAppURL] = useState('');
  const [iosAppURL, setiosAppURL] = useState('');
  const [whitePaperURL, setwhitePaperURL] = useState('');
  const [pitchDeck, setpitchDeck] = useState('');
  const [projectDesc, setprojectDesc] = useState('');
  const [projectTeam, setprojectTeam] = useState('');
  const [stateDevelopment, setstateDevelopment] = useState('');
  const [alreadyRaised, setalreadyRaised] = useState('');
  const [lookingAnts, setlookingAnts] = useState('');
  const [excludingAnts, setexcludingAnts] = useState('');
  const [flexing, setflexing] = useState('');
  const [whenLaunch, setwhenLaunch] = useState('');
  const [firstInvs, setfirstInvs] = useState('');
  const [secondInvs, setsecondInvs] = useState('');
  const [thirdInvs, setthirdInvs] = useState('');
  const [otherPlan, setotherPlan] = useState('');
  const [anyMarketAgen, setanyMarketAgen] = useState('');
  const [softwareAgen, setsoftwareAgen] = useState('');
  const [iconImage, seticonImage] = useState('');
  const [SliderImages, setSliderImages] = useState('');
  const [uid, setuid] = useState('');

  const [isOtherPlat, setisOtherPlat] = useState('');
  const [ngoName, setngoName] = useState('');
  const [howYouWantRaise, sethowYouWantRaise] = useState('');
  const [howYouSpend, sethowYouSpend] = useState('');

  const [isLoading, setisLoading] = useState(false);

  const [types, settypes] = useState([]);
  const [categories, setcategories] = useState([]);
  const [selectedCate, setselectedCate] = useState([]);

  const [seoData, setSeoData] = useState([]);
  useEffect(()=>{
    axios
      .get(`${global.backendUrl}/createSeo`)
      .then((res) => {
        setSeoData(res?.data);
      })
      .catch((err) => console.log(err));
  },[])
  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    const newImages = [
      ...SliderImages,
      ...selectedFiles.slice(0, 5 - SliderImages.length),
    ];
    setSliderImages(newImages);
  };

  const handleSave = async () => {
    if (!uid) {
      return showError('login again! There is an error');
    }
    if (!userName) {
      return showError('enter the user name');
    }
    if (!projectName) {
      return showError('enter your project name');
    }
    if (!projectType) {
      return showError('select the project type');
    }
    if (!projectCategory) {
      return showError('select the project category');
    }
    if (!pitchDeck) {
      return showError('select the pitch deck');
    }
    if (!projectDesc) {
      return showError('enter the project description');
    }
    if (!projectTeam) {
      return showError('enter about project team');
    }
    if (!stateDevelopment) {
      return showError('select the state of development');
    }
    if (!alreadyRaised) {
      return showError('enter how much you already have raised?');
    }
    if (!lookingAnts) {
      return showError('enter how much are you looking to raise with ANTS?');
    }
    if (!excludingAnts) {
      return showError(
        'enter how much are you looking to total raise excluding ANTS ?'
      );
    }
    if (!flexing) {
      return showError('select are you flexing with amount?');
    }
    if (!whenLaunch) {
      return showError('enter when would you like to launch?');
    }
    if (!firstInvs) {
      return showError('enter about first biggest invester');
    }
    if (!secondInvs) {
      return showError('enter about second biggest invester');
    }
    if (!thirdInvs) {
      return showError('enter about third biggest invester');
    }
    if (!otherPlan) {
      return showError('enter what other platforms, are you planning?');
    }
    if (!anyMarketAgen) {
      return showError('enter are you working with any marketing agency?');
    }
    if (!softwareAgen) {
      return showError(
        'enter are you working with any software development agency ?'
      );
    }
    if (!iconImage) {
      return showError('select the icon/image of project');
    }
    if (!SliderImages) {
      return showError('select the slider image of project');
    }

    function showError(fieldName) {
      toast.error(`Please ${fieldName}`, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    // const web3 = new Web3(window.ethereum);
    // await window.ethereum.enable();
    // // Get the current account
    // const accounts = await web3.eth.getAccounts();
    // const account = accounts[0];
    // let owner = account;
    // let weiValue = 0;
    // weiValue = Web3.utils.toWei(howYouWantRaise, "ether");
    // let contract = new web3.eth.Contract(ABI, contractAddress);
    // console.log(contract.methods);
    // const antsOwner = "0xf60416159E455aac8F61649c1454B1d5D94c8676"
    // const gasPrice = await web3.eth.getGasPrice();
    // console.log("gasPrice", gasPrice)
    try {
      // contract.methods
      //   .mint(account, howYouWantRaise)
      //   .send({
      //     from: owner,
      //     gas: 2000000,
      //     gasPrice: gasPrice,
      //     value: weiValue
      //   })
      //   .then(async function (result) {
      //     console.log("Transaction:", result)
    setisLoading(true);

          const formData = new FormData();
          formData.append('userName', userName);
          formData.append('facebook_url', facebook);
          formData.append('linkedin_url', linkedin);
          formData.append('discord_url', discord);
          formData.append('youtube_url', youtube);
          formData.append('project_name', projectName);
          formData.append('project_type', projectType);
          formData.append('project_category', projectCategory);
          formData.append('other_category', otherCate);
          formData.append('website_url', websiteURL);
          formData.append('androidApp_url', androidAppURL);
          formData.append('iosApp_url', iosAppURL);
          formData.append('whitepaper_url', whitePaperURL);
          formData.append('multipleImages', pitchDeck);
          formData.append('project_description', projectDesc);
          formData.append('project_team', projectTeam);
          formData.append('stateDevelopment', stateDevelopment);
          formData.append('hm_alreadyRaised', alreadyRaised);
          formData.append('hm_lookingRaiseWithAnts', lookingAnts);
          formData.append('hm_totalRaise', excludingAnts);
          formData.append('flexingAmount', flexing);
          formData.append('project_launchingDate', whenLaunch);
          formData.append('first_biggestInvs', firstInvs);
          formData.append('sec_biggestInvs', secondInvs);
          formData.append('third_biggestInvs', thirdInvs);
          formData.append('Other_platformsPlan', otherPlan);
          formData.append('AnyOther_marketAgen', anyMarketAgen);
          formData.append('AnyOther_softwareAgen', softwareAgen);
          formData.append('multipleImages', iconImage);
          SliderImages?.forEach((element) => {
            formData.append('multipleImages', element);
          });
          formData.append('uid', uid);
          formData.append('transaction_hash', "");

          const response = await axios.post(`${global.backendUrl}/project/insert`, formData);

          // Handle email sending if email and template exist
          const AccountInfo = localStorage.getItem('antsInformation');
          const parsedInfo = JSON.parse(AccountInfo);
          let email = parsedInfo?.email;
      
          if (email && emailTemplate.length > 0) {
            const templateStringReceive = emailTemplate[0].email_template_content;
            const finalTemplate = templateStringReceive
              .replace('@amount_to_raise', '$' + howYouWantRaise)
              .replace('@project_name', projectName);
      
            const formDataEmail = {
              user_email: email,
              subject: emailTemplate[0].email_template_subject,
              message: finalTemplate,
            };
      
            try {
              await axios.post(`${global.backendUrl}/sendEmail`, formDataEmail);
            } catch (emailError) {
              console.error('Email sending failed:', emailError);
              toast.warn('Project created, but email not sent.', {
                position: 'top-right',
              });
            }
          }
      
          navigate('/dashboard');
        } catch (error) {
          console.error('Error creating project:', error);
          toast.error('Failed to create the project. Please try again.');
        } finally {
          setisLoading(false);
        }
      };

  const handleNGO = async () => {
    if (!uid) {
      return showError('login again! There is an error');
    }
    if (!userName) {
      return showError('enter The user name');
    }
    if (!ngoName) {
      return showError('enter the ngo name');
    }
    if (!projectType) {
      return showError('select the project type');
    }
    // if (projectCategory == "Other") { if (!otherCate) { return showError("enter the other category") } }
    if (!projectCategory) {
      return showError('select the project category');
    }
    if (projectType.toLowerCase() === 'ngo' && !pitchDeck) {
      return showError('select the project document');
    }
    // if (!pitchDeck) {
    //   return showError('select the pitch deck');
    // }
    if (!projectDesc) {
      return showError('enter the project description');
    }
    if (!projectName) {
      return showError('enter your project name');
    }
    if (!alreadyRaised) {
      return showError('enter how much you already have raised?');
    }
    if (!howYouWantRaise) {
      return showError('enter how do you want to raise?');
    }
    if (!howYouSpend) {
      return showError('enter how you spend this fund');
    }
    if (!isOtherPlat) {
      return showError('select is any other platform are you using to raise?');
    }
    if (isOtherPlat == 'Yes') {
      if (!otherPlan) {
        return showError('enter other platform are you using to raise?');
      }
    }
    if (!iconImage) {
      return showError('select the icon/image of project');
    }
    if (!SliderImages) {
      return showError('select the slider image of project');
    }

    function showError(fieldName) {
      toast.error(`Please ${fieldName}`, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    // // setisLoading(true);
    // const web3 = new Web3(window.ethereum);
    // await window.ethereum.enable();
    // // Get the current account
    // const accounts = await web3.eth.getAccounts();
    // const account = accounts[0];
    // let owner = account;
    // let weiValue = 0;
    // // weiValue = Web3.utils.toWei(tokenAmount, "ether");
    // let contract = new web3.eth.Contract(ABI, contractAddress);
    // console.log(contract.methods);
    // const antsOwner = "0xf60416159E455aac8F61649c1454B1d5D94c8676"
    // console.log("contract", contract)
    // console.log("contract.methods", contract.methods)
    // const rewardAmount = howYouWantRaise * 0.1;
    // const tokenAmount = howYouWantRaise * 0.9;
    // console.log("rewardAmount", rewardAmount)
    // console.log("antsOwner", antsOwner)
    // console.log("account", account)
    // let weiValueToken = 0;
    // weiValueToken = Web3.utils.toWei(tokenAmount, "ether");
    // let weiValueReward = 0;
    // weiValueReward = Web3.utils.toWei(tokenAmount, "ether");
    // // weiValue = weiValueToken +  weiValueReward;
    // const gasPrice = await web3.eth.getGasPrice();
    // console.log("gasPrice", gasPrice)
    try {
      // contract.methods
      //   .mint(antsOwner, rewardAmount, account, tokenAmount)
      //   .send({
      //     from: owner,
      //     gas: 15000000,
      //     gas: 2000000,
      //     gasPrice: gasPrice,
      //     value: weiValue
      //   })
      //   .then(async function (result) {
      //     console.log("Transaction:", result)
    setisLoading(true);

          const formData = new FormData();
          formData.append('userName', userName);
          formData.append('ngo_name', ngoName);
          formData.append('project_name', projectName);
          formData.append('project_type', projectType);
          formData.append('project_category', projectCategory);
          formData.append('other_category', otherCate);
          formData.append('website_url', websiteURL);
          formData.append('multipleImages', pitchDeck);
          formData.append('project_description', projectDesc);
          formData.append('hm_alreadyRaised', alreadyRaised);
          formData.append('how_much_want_raise', howYouWantRaise);
          formData.append('how_spend_fund', howYouSpend);
          formData.append('is_anyOtherPlat', isOtherPlat);
          if (isOtherPlat == 'Yes') {
            formData.append('Other_platformsPlan', otherPlan);
          }
          formData.append('multipleImages', iconImage);
          SliderImages?.forEach((element) => {
            formData.append('multipleImages', element);
          });
          formData.append('uid', uid);
          formData.append('transaction_hash', "");

          const response = await axios.post(`${global.backendUrl}/project/insert`, formData);
    console.log("Project created successfully", response);

    // Handle email sending in a separate try-catch block
    const AccountInfo = localStorage.getItem('antsInformation');
    const parsedInfo = JSON.parse(AccountInfo);
    let email = parsedInfo?.email;

    if (email && emailTemplate.length > 0) {
      let templateStringReceive = emailTemplate[0].email_template_content;
      let finalTemplate = templateStringReceive
        .replace("@amount_to_raise", "$" + howYouWantRaise)
        .replace("@project_name", projectName);

      const formDataEmail = {
        user_email: email,
        subject: emailTemplate[0].email_template_subject,
        message: finalTemplate,
      };

      try {
        await axios.post(`${global.backendUrl}/sendEmail`, formDataEmail);
        console.log("Email sent successfully");

        // Navigate to dashboard after successful email sending
        navigate('/dashboard');
      } catch (emailError) {
        console.error('Email sending failed:', emailError);
        toast.warn('Project created, but email not sent.', {
          position: 'top-right',
        });
      }
    }
    navigate('/dashboard');
  } catch (error) {
    console.error('Error creating project:', error);
    toast.error('Failed to create the project. Please try again.');
  } finally {
    // Always reset the loading state
    setisLoading(false);
  }
};
  useEffect(() => {
    const AccountInfo = localStorage.getItem('antsInformation');
    const parsedInfo = JSON.parse(AccountInfo);
    setuid(parsedInfo?.uid);
    // setuserName(parsedInfo?.name)

    axios
      .get(`${global.backendUrl}/projecttype/get`)
      .then((response) => {
        settypes(response.data);
        setprojectType(response.data[0].type_name);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${global.backendUrl}/projectCate/get`)
      .then((response) => {
        setcategories(response.data);
      })
      .catch((err) => {
        console.log(err);
      });

    axios.get(`${global.backendUrl}/email_templates`)
      .then((res) => {
        const templates = res.data.filter((item) => item.email_template_type === "Launch Project Apply")
        setEmailTemplate(templates)
      })
      .catch((error) => {
        console.log(error);
      });


  }, []);

  const sod = [
    { title: 'MVP' },
    { title: 'Underdevelopment' },
    { title: 'Mature Already' },
    { title: 'Other' },
  ];

  const flexingAmount = [{ title: 'YES' }, { title: 'NO' }];

  useEffect(() => {
    setprojectCategory('');
    setotherCategory(false);
    const filterData = categories.filter((t) => t.project_type == projectType);
    setselectedCate(filterData);
  }, [projectType, categories]);

  useEffect(() => {
    const filterData = categories.filter((t) => t.project_type == projectType);
    const filter2 = filterData.filter((t) => t.cate_name == 'Other');
    if (filter2.length !== 0 && filter2[0].cate_id == projectCategory) {
      setotherCategory(true);
    } else {
      setotherCategory(false);
    }
  }, [projectCategory, categories, projectType]);

  return (
    <div>
      {seoData[0] && (
        <Helmet>
          <title>{seoData[0]?.create_meta_title}</title>

          <meta name="description" content={seoData[0]?.create_meta_description} />
          <meta name="keywords" content={JSON.parse(seoData[0]?.create_meta_tags)} />
          <meta name="robots" content="index,follow" />
          <meta property="og:title" content={seoData[0]?.create_meta_title} />
          <meta property="og:description" content={seoData[0]?.create_meta_description} />
          <meta
            property="og:image"
            content={`${global.imageURL}/nodeassets/${seoData[0]?.create_meta_image}`}
          />
          <meta property="og:type" content="website" />
          <meta name="twitter:card" content={seoData[0]?.create_meta_title} />
          <meta name="twitter:title" content={seoData[0]?.create_meta_title} />
          <meta
            name="twitter:description"
            content={seoData[0]?.create_meta_description}
          />

          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <link rel="favicon" href="/favicon.ico" />
          <meta
            name="twitter:image"
            content={`${global.imageURL}/nodeassets/${seoData[0]?.create_meta_image}`}
          />
        </Helmet>
      )}
      <PageTitle none="none" title="Create Project" />

      <section className="tf-add-nft">
        <div className="tf-container">
          <div className="row ">
            <div className="col-xl-9 col-lg-8 ">
              <div className="add-nft-inner">
                <h6 className="title">Project Type</h6>
                <Tabs className="tf-tab">
                  <TabList
                    className="create-button menu-tab"
                    style={{ borderBottom: 'none' }}
                  >
                    {types?.map((t, i) => (
                      <Tab onClick={() => setprojectType(t.type_name)}>
                        <div className="create-item">
                          <div className="icon">
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M14.19 0H5.81C2.17 0 0 2.17 0 5.81V14.18C0 17.83 2.17 20 5.81 20H14.18C17.82 20 19.99 17.83 19.99 14.19V5.81C20 2.17 17.83 0 14.19 0ZM4.47 5.72C4.76 5.43 5.24 5.43 5.53 5.72C6.24 6.43 7.4 6.43 8.11 5.72C8.4 5.43 8.88 5.43 9.17 5.72C9.46 6.01 9.46 6.49 9.17 6.78C8.52 7.43 7.67 7.75 6.82 7.75C5.97 7.75 5.12 7.43 4.47 6.78C4.18 6.48 4.18 6.01 4.47 5.72ZM10 16.78C7.31 16.78 5.12 14.59 5.12 11.9C5.12 11.2 5.69 10.62 6.39 10.62H13.59C14.29 10.62 14.86 11.19 14.86 11.9C14.88 14.59 12.69 16.78 10 16.78ZM15.53 6.78C14.88 7.43 14.03 7.75 13.18 7.75C12.33 7.75 11.48 7.43 10.83 6.78C10.54 6.49 10.54 6.01 10.83 5.72C11.12 5.43 11.6 5.43 11.89 5.72C12.6 6.43 13.76 6.43 14.47 5.72C14.76 5.43 15.24 5.43 15.53 5.72C15.82 6.01 15.82 6.48 15.53 6.78Z"
                                fill="white"
                              />
                            </svg>
                          </div>
                          <span>{t.type_name}</span>
                        </div>
                      </Tab>
                    ))}
                  </TabList>
                </Tabs>

                <fieldset>
                  <label>Select Category *</label>
                  <div>
                    <select
                      className="custom-select"
                      value={projectCategory}
                      onChange={(e) => setprojectCategory(e.target.value)}
                    >
                      <option value="">Select Project Category</option>
                      {selectedCate?.map((t, i) => (
                        <option value={t.cate_id}>{t.cate_name}</option>
                      ))}
                    </select>
                  </div>
                </fieldset>

                {otherCategory && (
                  <fieldset>
                    <input
                      type="text"
                      value={otherCate}
                      onChange={(e) => setotherCate(e.target.value)}
                      placeholder="Enter Other Category"
                    />
                  </fieldset>
                )}

                <fieldset>
                  <label>Your Name *</label>
                  <input
                    type="text"
                    placeholder="Enter Your Name Here"
                    value={userName}
                    onChange={(e) => setuserName(e.target.value)}
                  />
                </fieldset>

                {projectType.toLowerCase() == 'ngo' ? (
                  <fieldset>
                    <label>NGO Name *</label>
                    <div className="row">
                      <div className="col-12">
                        <input
                          type="text"
                          value={ngoName}
                          onChange={(e) => setngoName(e.target.value)}
                          placeholder="Enter NGO Name"
                        />
                      </div>
                    </div>
                  </fieldset>
                ) : (
                  <fieldset>
                    <label>Your Social Media Handels</label>
                    <div className="row">
                      <div className="col-6">
                        <input
                          type="text"
                          placeholder="Enter Facebook Account Link"
                          value={facebook}
                          onChange={(e) => setfacebook(e.target.value)}
                        />
                      </div>
                      <div className="col-6">
                        <input
                          type="text"
                          placeholder="Enter Linkedin Account Link"
                          value={linkedin}
                          onChange={(e) => setlinkedin(e.target.value)}
                        />
                      </div>
                      <div className="col-6 pt-4">
                        <input
                          type="text"
                          placeholder="Enter Discord Account Link"
                          value={discord}
                          onChange={(e) => setdiscord(e.target.value)}
                        />
                      </div>
                      <div className="col-6 pt-4">
                        <input
                          type="text"
                          placeholder="Enter Youtube Account Link"
                          value={youtube}
                          onChange={(e) => setyoutube(e.target.value)}
                        />
                      </div>
                    </div>
                  </fieldset>
                )}

                <fieldset>
                  <label>Project Name *</label>
                  <input
                    type="text"
                    placeholder="Enter The Project Name Here"
                    value={projectName}
                    onChange={(e) => setprojectName(e.target.value)}
                  />
                </fieldset>

                {projectType.toLowerCase() == 'ngo' ? (
                  <fieldset>
                    <label>URL's</label>
                    <div className="row">
                      <div className="col-12">
                        <input
                          type="text"
                          placeholder="Enter The Website URL"
                          value={websiteURL}
                          onChange={(e) => setwebsiteURL(e.target.value)}
                        />
                      </div>
                    </div>
                  </fieldset>
                ) : (
                  <fieldset>
                    <label>URL's</label>
                    <div className="row">
                      <div className="col-6">
                        <input
                          type="text"
                          placeholder="Enter The Website URL"
                          value={websiteURL}
                          onChange={(e) => setwebsiteURL(e.target.value)}
                        />
                      </div>
                      <div className="col-6">
                        <input
                          type="text"
                          placeholder="Enter The Android App URL"
                          value={androidAppURL}
                          onChange={(e) => setandroidAppURL(e.target.value)}
                        />
                      </div>
                      <div className="col-6 pt-4">
                        <input
                          type="text"
                          placeholder="Enter The Ios App URL"
                          value={iosAppURL}
                          onChange={(e) => setiosAppURL(e.target.value)}
                        />
                      </div>
                      <div className="col-6 pt-4">
                        <input
                          type="text"
                          placeholder="Enter The White Paper URL"
                          value={whitePaperURL}
                          onChange={(e) => setwhitePaperURL(e.target.value)}
                        />
                      </div>
                    </div>
                  </fieldset>
                )}

                <fieldset>
                  <label>
                    {projectType.toLowerCase() == 'ngo'
                      ? 'Project document *'
                      : 'Pitch deck *'}
                  </label>
                  <input
                    type="file"
                    onChange={(e) => setpitchDeck(e.target.files[0])}
                    placeholder="E.G. Redeemable  T-Shirt With Logo "
                  />
                </fieldset>

                <fieldset>
                  <h6 className="title">Project Description *</h6>
                  {/* <textarea value={projectDesc} onChange={e => setprojectDesc(e.target.value)} id="message" name="message" rows="4" placeholder="Enter Description Here" tabIndex="4" aria-required="true" required="" /> */}
                  <ReactQuill
                    value={projectDesc}
                    onChange={(e) => setprojectDesc(e)}
                    // placeholder="Type something here..."
                    modules={{
                      toolbar: [
                        [{ header: [1, 2, 3, 4, false] }],
                        ['bold', 'italic', 'underline', 'strike'],
                        [{ color: [] }, { background: [] }],
                        [{ align: [] }],
                        [{ list: 'ordered' }, { list: 'bullet' }],
                        ['link', 'image', 'video'],
                        ['clean'],
                      ],
                    }}
                    formats={[
                      'header',
                      'bold',
                      'italic',
                      'underline',
                      'strike',
                      'color',
                      'background',
                      'align',
                      'list',
                      'bullet',
                      'link',
                      'image',
                      'video',
                    ]}
                    style={{
                      height: '50vh',
                      marginBottom: '55px',
                      marginTop: '10px',
                      color: 'white',
                    }}
                  />
                </fieldset>

                {projectType.toLowerCase() == 'ngo' ? (
                  <>
                    <fieldset>
                      <div className="row">
                        <div className="col-6">
                          <label>How much do you want to raise? *</label>
                          <input
                            type="number"
                            value={howYouWantRaise}
                            onChange={(e) => sethowYouWantRaise(e.target.value)}
                            placeholder="Enter Here"
                          />
                        </div>
                        <div className="col-6">
                          <label>How will you spend this fund? *</label>
                          <input
                            type="text"
                            value={howYouSpend}
                            onChange={(e) => sethowYouSpend(e.target.value)}
                            placeholder="Enter Here"
                          />
                        </div>
                        <div className="col-6 pt-4">
                          <label>How much you already have raised? *</label>
                          <input
                            type="number"
                            value={alreadyRaised}
                            onChange={(e) => setalreadyRaised(e.target.value)}
                            placeholder="Enter Here"
                          />
                        </div>
                        <div className="col-6 pt-4">
                          <label>
                            Is there any other platform are you using to raise?
                            *
                          </label>
                          <div>
                            <select
                              className="custom-select mb-0"
                              value={isOtherPlat}
                              onChange={(e) => setisOtherPlat(e.target.value)}
                            >
                              <option>Select Here</option>
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </select>
                          </div>
                        </div>
                        {isOtherPlat == 'Yes' && (
                          <div className="col-6 pt-4">
                            <label>Which One? *</label>
                            <input
                              type="text"
                              value={otherPlan}
                              onChange={(e) => setotherPlan(e.target.value)}
                              placeholder="Enter Here"
                            />
                          </div>
                        )}
                      </div>
                    </fieldset>
                  </>
                ) : (
                  <>
                    <fieldset>
                      <label>Project Team *</label>
                      <input
                        type="text"
                        placeholder="Enter About Your Team"
                        value={projectTeam}
                        onChange={(e) => setprojectTeam(e.target.value)}
                      />
                    </fieldset>

                    <fieldset>
                      <label>State of Development *</label>
                      <div>
                        <select
                          className="custom-select mb-0"
                          value={stateDevelopment}
                          onChange={(e) => setstateDevelopment(e.target.value)}
                        >
                          <option>Select Here</option>
                          {sod?.map((t, i) => (
                            <option value={t.title}>{t.title}</option>
                          ))}
                        </select>
                      </div>
                    </fieldset>

                    <fieldset>
                      <div className="row">
                        <div className="col-6">
                          <label>How much you already have raised? *</label>
                          <input
                            type="number"
                            value={alreadyRaised}
                            onChange={(e) => setalreadyRaised(e.target.value)}
                            placeholder="Enter Here"
                          />
                        </div>
                        <div className="col-6">
                          <label>
                            How much are you looking to raise with ANTS? *
                          </label>
                          <input
                            type="number"
                            value={lookingAnts}
                            onChange={(e) => setlookingAnts(e.target.value)}
                            placeholder="Enter Here"
                          />
                        </div>
                        <div className="col-6 pt-4">
                          <label>
                            How much are you looking to total raise excluding
                            ANTS ? *
                          </label>
                          <input
                            type="number"
                            value={excludingAnts}
                            onChange={(e) => setexcludingAnts(e.target.value)}
                            placeholder="Enter Here"
                          />
                        </div>
                      </div>
                    </fieldset>

                    <fieldset>
                      <div className="row">
                        <div className="col-6">
                          <label>Are you flexing with amount? *</label>
                          <div>
                            <select
                              className="custom-select mb-0"
                              value={flexing}
                              onChange={(e) => setflexing(e.target.value)}
                            >
                              <option>Select Here</option>
                              {flexingAmount?.map((t, i) => (
                                <option value={t.title}>{t.title}</option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="col-6">
                          <label>When would you like to launch? *</label>
                          <input
                            type="date"
                            className="white-calendar"
                            value={whenLaunch}
                            onChange={(e) => setwhenLaunch(e.target.value)}
                            placeholder="Enter Here"
                          />
                        </div>
                      </div>
                    </fieldset>

                    <fieldset>
                      <label>
                        Please share your three biggest 3 investors, as well as
                        their average ticket price? *
                      </label>
                      <div className="row">
                        <div className="col-6">
                          <input
                            value={firstInvs}
                            onChange={(e) => setfirstInvs(e.target.value)}
                            type="text"
                            placeholder="Enter About First Biggest Investor"
                          />
                        </div>
                        <div className="col-6">
                          <input
                            value={secondInvs}
                            onChange={(e) => setsecondInvs(e.target.value)}
                            type="text"
                            placeholder="Enter About Second Biggest Investor"
                          />
                        </div>
                        <div className="col-6 pt-4">
                          <input
                            type="text"
                            value={thirdInvs}
                            onChange={(e) => setthirdInvs(e.target.value)}
                            placeholder="Enter About Third Biggest Investor"
                          />
                        </div>
                      </div>
                    </fieldset>

                    <fieldset>
                      <div className="row">
                        <div className="col-6">
                          <label>
                            What other platforms, are you planning? *
                          </label>
                          <input
                            type="text"
                            value={otherPlan}
                            onChange={(e) => setotherPlan(e.target.value)}
                            placeholder="Enter Here"
                          />
                        </div>
                        <div className="col-6">
                          <label>
                            Are you working with any marketing agency? *
                          </label>
                          <div>
                            <select
                              className="custom-select mb-0"
                              value={anyMarketAgen}
                              onChange={(e) => setanyMarketAgen(e.target.value)}
                            >
                              <option>Select Here</option>
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </select>
                          </div>
                          {/* <input type="text" value={anyMarketAgen} onChange={e => setanyMarketAgen(e.target.value)} placeholder="Enter Here" /> */}
                        </div>
                        <div className="col-12 pt-4">
                          <label>
                            Are you working with any software development agency
                            ? *
                          </label>
                          <div>
                            <select
                              className="custom-select mb-0"
                              value={softwareAgen}
                              onChange={(e) => setsoftwareAgen(e.target.value)}
                            >
                              <option>Select Here</option>
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </select>
                          </div>
                          {/* <input type="text" value={softwareAgen} onChange={e => setsoftwareAgen(e.target.value)} placeholder="Enter Here" /> */}
                        </div>
                      </div>
                    </fieldset>
                  </>
                )}

                {/* <TabPanel> */}
                <div className="tab-create-item">
                  <h6 className="title">Project Icon Image *</h6>
                  <div className="drag-upload">
                    <input
                      type="file"
                      onChange={(e) => seticonImage(e.target.files[0])}
                    />
                    <img
                      src={
                        iconImage ? URL.createObjectURL(iconImage) : drapupload
                      }
                      alt="ANTS"
                    />
                    <h6 className="title">Drag your item to upload</h6>
                    {/* <p className="sub-title">
                          PNG, GIF, WebP, MP4 Or MP3. Maximum File Size 100 Mb.
                        </p> */}
                  </div>
                </div>

                <div className="tab-create-item">
                  <h6 className="title">Project Slider images. *</h6>
                  <div className="drag-upload">
                    <input type="file" multiple onChange={handleFileChange} />
                    {SliderImages ? (
                      <div>
                        {' '}
                        Images Selected{' '}
                        <i class="fa-solid fa-circle-check text-success"></i>{' '}
                      </div>
                    ) : (
                      <img src={drapupload} alt="ANTS" />
                    )}
                    <h6 className="title">Drag your item to upload</h6>
                    <p className="sub-title">(Select Mulitple if you want!!)</p>
                  </div>

                  <div className="bottom-button">
                    {projectType.toLowerCase() == 'ngo' ? (
                      <button
                        onClick={handleNGO}
                        disabled={isLoading}
                        className="tf-button active"
                      >
                        {isLoading ? 'Loading...' : 'Save'}
                      </button>
                    ) : (
                      <button
                        onClick={handleSave}
                        disabled={isLoading}
                        className="tf-button active"
                      >
                        {isLoading ? 'Loading...' : 'Save'}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="col-xl-3 col-lg-4 col-md-6">
              <h5 className="title-preview">Item Preview</h5>
              <div className="sc-product style1">
                <div className="top">
                  <Link to="#" className="tag">
                    Sweet Baby #1
                  </Link>
                  <div className="wish-list">
                    <Link to="#" className="heart-icon"></Link>
                  </div>
                </div>
                <div className="features">
                  <div className="product-media">
                    <img
                      src={
                        "https://binaseareact.vercel.app/static/media/product4.ffb5c1b8626991bd54cf.jpg"
                      }
                      alt="images"
                    />
                  </div>
                  <div className="featured-countdown">
                    <span
                      className="js-countdown"
                      data-timer="55555"
                      data-labels=" ,  h , m , s "
                    ></span>
                  </div>
                  <div className="rain-drop1">
                    <img src={ico1} alt="images" />
                  </div>
                  <div className="rain-drop2">
                    <img src={ico2} alt="images" />
                  </div>
                </div>
                <div className="bottom">
                  <div className="details-product">
                    <div className="author">
                      <div className="avatar">
                        <img src={bloguser} alt="images" />
                      </div>
                      <div className="content">
                        <div className="position">Creator</div>
                        <div className="name">
                          {" "}
                          <Link to="#">Carly Webster</Link>
                        </div>
                      </div>
                    </div>
                    <div className="current-bid">
                      <div className="subtitle">Current bid</div>
                      <div className="price">
                        <span className="cash">5 ETH</span>
                        <span className="icon">
                          <img src={ico3} alt="images" />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="product-button">
                    <Link
                      to="#"
                      data-toggle="modal"
                      data-target="#popup_bid"
                      className="tf-button"
                    >
                      {" "}
                      <span className="icon-btn-product"></span> Place Bid
                    </Link>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </section>
    </div>
  );
}

export default Create;

{
  /* <h6 className="title">Choose Blockchain</h6>
                <p className="sub">
                  Connect With One Of Our Available Wallet Providers Or Create A
                  New One.
                </p>
                <ul className="blockchain-button">
                  <li>
                    <Link to="#">
                      <img src={metamaskwallet} alt="ANTS" />
                      MetaMask
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <img src={coinbasewallet} alt="ANTS" />
                      Coinbase
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <img src={toruswallet} alt="ANTS" />
                      Torus
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <img src={fortmaticwallet} alt="ANTS" />
                      Fortmatic
                    </Link>
                  </li>
                  <li>
                    <Link to="#">Show more options</Link>
                  </li>
                </ul> */
}

{
  /* <Tab>
                      <div className="create-item">
                        <div className="icon">
                          <svg
                            width="25"
                            height="24"
                            viewBox="0 0 25 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M10.748 1H4.74805C3.08805 1 1.74805 2.34 1.74805 4V10C1.74805 11.66 3.08805 13 4.74805 13H9.24805C9.24805 10.52 11.268 8.5 13.748 8.5V4C13.748 2.34 12.408 1 10.748 1ZM4.51805 4.27C5.28805 3.72 6.33805 3.71 7.12805 4.25C7.46805 4.48 7.55805 4.95 7.32805 5.29C7.09805 5.63 6.62805 5.72 6.28805 5.49C6.01805 5.3 5.64805 5.3 5.37805 5.5C5.24805 5.59 5.09805 5.63 4.94805 5.63C4.71805 5.63 4.47805 5.52 4.33805 5.32C4.09805 4.98 4.17805 4.51 4.51805 4.27ZM10.018 8.24C9.75805 8.57 9.28805 8.62 8.96805 8.36C8.61805 8.08 8.19805 7.93 7.74805 7.93C6.74805 7.93 5.91805 8.69 5.80805 9.67H7.90805C8.31805 9.67 8.65805 10.01 8.65805 10.42C8.65805 10.83 8.31805 11.17 7.90805 11.17H5.58805C4.87805 11.17 4.29805 10.59 4.29805 9.88C4.29805 7.98 5.84805 6.43 7.74805 6.43C8.52805 6.43 9.29805 6.7 9.90805 7.19C10.228 7.45 10.278 7.92 10.018 8.24ZM11.328 5.29C11.098 5.63 10.628 5.72 10.288 5.49C10.018 5.3 9.64805 5.3 9.37805 5.5C9.24805 5.59 9.09805 5.63 8.94805 5.63C8.71805 5.63 8.47805 5.52 8.33805 5.32C8.09805 4.98 8.17805 4.51 8.51805 4.27C9.28805 3.72 10.338 3.71 11.128 4.25C11.468 4.48 11.558 4.95 11.328 5.29Z"
                              fill="white"
                            />
                            <path
                              d="M18.9678 16.6786C18.8978 16.5986 18.7978 16.5586 18.6878 16.5586H14.8078C14.6978 16.5586 14.5978 16.5986 14.5278 16.6786C14.4578 16.7586 14.4178 16.8686 14.4378 16.9686C14.5678 18.1486 15.5578 19.0486 16.7478 19.0486C17.9378 19.0486 18.9278 18.1586 19.0578 16.9686C19.0678 16.8586 19.0378 16.7586 18.9678 16.6786Z"
                              fill="white"
                            />
                            <path
                              d="M19.748 10H13.748C12.098 10 10.748 11.35 10.748 13V19C10.748 20.65 12.098 22 13.748 22H19.748C21.398 22 22.748 20.65 22.748 19V13C22.748 11.35 21.398 10 19.748 10ZM13.338 13.17C13.578 12.83 14.048 12.75 14.388 12.99C14.658 13.18 15.018 13.18 15.288 13C15.628 12.76 16.098 12.85 16.328 13.2C16.558 13.54 16.478 14.01 16.128 14.24C15.738 14.5 15.288 14.64 14.838 14.64C14.368 14.64 13.908 14.5 13.518 14.22C13.178 13.97 13.098 13.5 13.338 13.17ZM16.748 20.17C14.848 20.17 13.298 18.62 13.298 16.72C13.298 16.01 13.878 15.43 14.588 15.43H18.908C19.618 15.43 20.198 16.01 20.198 16.72C20.198 18.62 18.648 20.17 16.748 20.17ZM20.128 14.23C19.738 14.49 19.288 14.63 18.838 14.63C18.368 14.63 17.908 14.49 17.518 14.21C17.178 13.97 17.098 13.5 17.338 13.16C17.578 12.82 18.048 12.74 18.388 12.98C18.658 13.17 19.018 13.17 19.288 12.99C19.628 12.75 20.098 12.84 20.328 13.19C20.558 13.54 20.468 14 20.128 14.23Z"
                              fill="white"
                            />
                          </svg>
                        </div>
                        <span>Create collection</span>
                      </div>
                    </Tab> */
}
