import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Link, useLocation } from "react-router-dom";
import img from "../assets/images/background/thumb-pagetitle.jpg";
import { bloguser, sellerimg } from "../utils/Assets";
import AccountSetting from "./components/AccountSetting";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useDashboard } from "../hooks/dashboard";
import { useStateContext } from "../components/context/AppContext";
import { toast } from "react-toastify";
import Web3 from "web3";
import ABIStaking from "../utils/abi-staking";
import { contractAddress, stakingContractAddress } from "../utils/utils";
import ABI from "../utils/abi";
Dashboard.propTypes = {};
// deployment
function Dashboard(props) {
  const {
    isAuthenticated,
    saleData,
    setSaleData,
    proposalData,
    formattedProposalData,
  } = useStateContext();
  const navigate = useNavigate();
  const [linkClick, setLinkClick] = useState(false);
  const [productId, setProductId] = useState(0);
  const [data, setdata] = useState([]);
  const [projects, setprojects] = useState([]);
  const [userImg, setuserImg] = useState("");
  const [coverImg, setcoverImg] = useState("");
  const [affiliatedUsers, setAffiliatedUsers] = useState([]);

  const [fb, setfb] = useState("");
  const [twitter, settwitter] = useState("");
  const [discord, setdiscord] = useState("");
  const [linkedin, setlinkedin] = useState("");
  const [medium, setmedium] = useState("");
  const [reddit, setreddit] = useState("");
  const [tokenValue,setTokenValue]=useState(0)
  const [purchasedTickets, setPurchasedTickets] = useState([]);
  const [airDropTokens, setAirDropTokens] = useState([]);
  const [seoData, setSeoData] = useState([]);
  const [walletAddres, setWalletAddress] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);
  const location = useLocation();
  const { dataAPI, getData, userUid, filteredNotifications, saleAPI } =
    useDashboard();

  const dummyImage =
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQD116U9ZCk8bEaanCeB5rSCC2uqY5Ka_2_EA&usqp=CAU";

    // const img='https://www.ntc.edu/sites/default/files/styles/full_width_16_9/public/2021-06/digital-marketing-header.jpg?itok=YZDb8Tug'
  useEffect(() => {
    const AccountInfo = localStorage.getItem("antsInformation");
    const parsedInfo = JSON.parse(AccountInfo);

    axios
      .get(`${global.backendUrl}/signup/get`)
      .then((response) => {
        const filteredData = response.data.filter(
          (t) => t.uid == parsedInfo?.uid
        );

        setdata(filteredData);
        setWalletAddress(filteredData[0]?.wallet_address || null);
        setuserImg(filteredData[0]?.image);
        setcoverImg(filteredData[0]?.cover_img);
        setfb(filteredData[0]?.link_fb);
        settwitter(filteredData[0]?.link_twitter);
        setdiscord(filteredData[0]?.link_discord);
        setlinkedin(filteredData[0]?.link_linkedin);
        setmedium(filteredData[0]?.link_medium);
        setreddit(filteredData[0]?.link_reddit);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${global.backendUrl}/dashboard-projects/get`)
      .then((response) => {
        const filteredData = response.data.filter(
          (t) => t.uid == parsedInfo?.uid
        );
        setprojects(filteredData);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${global.backendUrl}/tickets/purchase/${parsedInfo?.uid}`)
      .then((res) => {
        setPurchasedTickets(res.data);
      })
      .catch((err) => console.log(err));

    axios
      .get(`${global.backendUrl}/profileSeo`)
      .then((res) => {
        setSeoData(res?.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const fetchData = async () => {
    const AccountInfo = localStorage.getItem("antsInformation");
    const parsedInfo = JSON.parse(AccountInfo);

    axios
      .get(`${global.backendUrl}/signup/get`)
      .then((response) => {
        const filteredData = response.data.filter(
          (t) => t.uid == parsedInfo?.uid
        );
        setdata(filteredData);
        setWalletAddress(filteredData[0]?.wallet_address || null);
        setuserImg(filteredData[0]?.image);
        setcoverImg(filteredData[0]?.cover_img);
        setfb(filteredData[0]?.link_fb);
        settwitter(filteredData[0]?.link_twitter);
        setdiscord(filteredData[0]?.link_discord);
        setlinkedin(filteredData[0]?.link_linkedin);
        setmedium(filteredData[0]?.link_medium);
        setreddit(filteredData[0]?.link_reddit);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const fetchAirdropData = async () => {
      const AccountInfo = localStorage.getItem("antsInformation");
      const parsedInfo = JSON.parse(AccountInfo);

      if (parsedInfo?.wallet_address) {
        try {
          const response = await fetch(
            `${global.backendUrl}/airdrop/check?wallet_address=${parsedInfo.wallet_address}`
          );
          const data = await response.json();
          if (response.ok) {
            setAirDropTokens(data.data);
          } else {
            console.error(data.message || "Error fetching airdrop data");
          }
        } catch (error) {
          console.error("Fetch error:", error);
        }
      } else {
        console.error("Wallet address not found in localStorage.");
      }
    };

    fetchAirdropData();
  }, []);

  const handleDelete = (id) => {
    axios.delete(`${global.backendUrl}/project/delete/${id}`);
    const filterArr = projects.filter((val) => val.project_id !== id);
    setprojects(filterArr);
  };

  useEffect(() => {
    const stateTabIndex = location.state?.tabIndex;
    const savedTabIndex = localStorage.getItem("dashboardTabIndex");
    setTabIndex(
      stateTabIndex !== undefined
        ? stateTabIndex
        : savedTabIndex
        ? parseInt(savedTabIndex, 10)
        : 1
    );
  }, [location.state]);

  const handleTabChange = (index) => {
    setTabIndex(index);
    localStorage.setItem("dashboardTabIndex", index);
  };
  const formatDateTime = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });

    const formattedTime = date.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: false,
    });

    return `${formattedDate}, ${formattedTime}`;
  };

  const filterdSale = saleAPI.filter(
    (sale) => sale.private_sale_status === "Active"
  );
  const formattedSaleData = filterdSale.map((data) => ({
    ...data,
    private_sale_start_time_formatted: formatDateTime(
      data.private_sale_start_time
    ),
    private_sale_end_time_formatted: formatDateTime(data.private_sale_end_time),
  }));


  const handleNavigateToPrivateSale = (data) => {
    console.log("data", data);
    if (data) {
      setSaleData(data)
      navigate("/private-sale-module", { state: data });
    }
  };

  useEffect(() => {
    const accountInfo = localStorage.getItem("antsInformation");
    const parsedInfo = JSON.parse(accountInfo);
    if (parsedInfo?.uid) {
      axios
        .get(`${global.backendUrl}/users/affiliates/${parsedInfo.uid}`)
        .then((response) => {
          setAffiliatedUsers(response.data);
        })
        .catch((error) => {
          console.error("Failed to fetch affiliates:", error);
        });
    }
  }, []);

  useEffect(() => {
    getData();
  }, []);

  const handleShareLink = () => {
    const AccountInfo = localStorage.getItem("antsInformation");
    const parsedInfo = JSON.parse(AccountInfo);

    const affiliateLink = `${window.location.origin}/login?affiliate_id=${parsedInfo?.uid}`;
    navigator.clipboard
      .writeText(affiliateLink)
      .then(() => {
        alert("Affiliate link copied to clipboard!");
        // axios.post(`http://localhost:5000/users/update/affiliate`, {
        //   uid: parsedInfo?.uid,
        //   affiliate_id: parsedInfo?.uid,
        // })
        // .then((response) => {
        //   console.log(response.data);
        // })
        // .catch((error) => {
        //   console.error("Failed to update affiliated ID:", error);
        // });
      })
      .catch((err) => {
        console.error("Failed to copy affiliate link:", err);
      });
  };

  const handleClaim = async (id) => {
    try {
      const web3 = new Web3(window.ethereum);
      await window.ethereum.enable();
      const accounts = await web3.eth.getAccounts();
      const account = accounts[0];
      const contract = new web3.eth.Contract(ABIStaking, stakingContractAddress);
  
      const gasPrice = await web3.eth.getGasPrice();
      console.log("Gas Price:", gasPrice);
  
      await contract.methods
        .claimAirdrop()
        .send({
          from: account,
          gas: 2000000,
          gasPrice: gasPrice,
          value: 0,
        })
        .then(async (result) => {
          console.log("Transaction successful:", result);
          toast.success("Transaction successful");
  
          const response = await fetch(`${global.backendUrl}/airdrop/claim/${id}`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
          });
  
          if (response.ok) {
            const data = await response.json();
            console.log("Claim successful:", data);
            toast.success("Claim marked successfully");
          } else {
            console.error("Error in marking claim:", response.statusText);
            toast.error("Claim marking failed. Please contact support.");
          }
        })
        .catch((error) => {
          console.error("Transaction failed:", error);
          toast.error("Transaction failed. Please try again.");
        });
    } catch (error) {
      console.error("Unexpected error in handleClaim:", error);
      toast.error("An unexpected error occurred. Please try again.");
    }
  };
  

  const isClaimableDate = (createdAt, durationDays) => {
    const createdDate = new Date(createdAt);
    const claimableDate = new Date(
      createdDate.getTime() + durationDays * 24 * 60 * 60 * 1000
    );
    return new Date() >= claimableDate;
  };
  useEffect(() => {
    const fetchWalletAddress = async () => {
      const fetchedData = await data;  
      setWalletAddress(fetchedData[0]?.wallet_address || null);
    };

    fetchWalletAddress();
  }, []);
  
 
  const getTokenValue= async()=>{
    console.log('sssss',walletAddres)
    if (!walletAddres) {
    toast.error("Wallet address is undefined.");
      return;
    }

  try {
    const web3 = new Web3(window.ethereum);
    await window.ethereum.enable();
    const accounts = await web3.eth.getAccounts();
    const account = accounts[0];
    const contract = new web3.eth.Contract(ABI, contractAddress);

    // const gasPrice = await web3.eth.getGasPrice();
    // console.log("Gas Price:", gasPrice);
    await contract.methods
      .balanceOf(walletAddres)
      .call()
      .then(async (result) => {
        console.log("Transaction successful:", result);
        console.log(result)
        setTokenValue(result)
      })
      .catch((error) => {
        console.error("Transaction failed:", error);
      });
  } catch (error) {
    console.error("Unexpected error in getTokenValue:", error);
  }
}
useEffect(() => {
  if (walletAddres) {
    getTokenValue();
  } else {
    console.log("Waiting for Wallet Address...");
  }
}, [walletAddres]);


  return (
    <div>
      {seoData[0] && (
        <Helmet>
          <title>{seoData[0]?.profile_meta_title}</title>

          <meta
            name="description"
            content={seoData[0]?.profile_meta_description}
          />
          <meta
            name="keywords"
            content={JSON.parse(seoData[0]?.profile_meta_tags)}
          />
          <meta name="robots" content="index,follow" />
          <meta property="og:title" content={seoData[0]?.profile_meta_title} />
          <meta
            property="og:description"
            content={seoData[0]?.profile_meta_description}
          />
          <meta
            property="og:image"
            content={`${global.imageURL}/nodeassets/${seoData[0]?.profile_meta_image}`}
          />
          <meta property="og:type" content="website" />
          <meta name="twitter:card" content={seoData[0]?.profile_meta_title} />
          <meta name="twitter:title" content={seoData[0]?.profile_meta_title} />
          <meta
            name="twitter:description"
            content={seoData[0]?.profile_meta_description}
          />

          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <link rel="favicon" href="/favicon.ico" />
          <meta
            name="twitter:image"
            content={`${global.imageURL}/nodeassets/${seoData[0]?.profile_meta_image}`}
          />
        </Helmet>
      )}
      <section class="tf-page-title ">
        {/* <div class="tf-container">
          <div class="row">
            <div class="col-md-12">
              <ul class="breadcrumbs">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>Profile</li>
              </ul>
            </div>
          </div>
        </div> */}
        <div class="container-fluid">
          <div class="row">
            <div class="thumb-pagetitle w-100">
              <img
                className="w-100"
                style={{ objectFit: "cover" }}
                src={
                  coverImg ? `${global.imageURL}/nodeassets/${coverImg}` : img
                }
                alt="images"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="tf-dashboard tf-tab">
        <div className="tf-container">
          <Tabs
            selectedIndex={tabIndex}
            onSelect={handleTabChange}
            className="dashboard-filter"
          >
            <div className="row ">
              <div className="col-xl-3 col-lg-12 col-md-12" >
                <div className="dashboard-user" style={{display:'flex', flexDirection:'column',alignItems:'center'}}>
                  <div className="dashboard-infor">
                    <div className="avatar">
                      <img
                        src={
                          userImg
                            ? `${global.imageURL}/nodeassets/${userImg}`
                            : dummyImage
                        }
                        alt="images"
                        style={{ height: "120px" }}
                      />
                    </div>
                    <div className="name">{data[0]?.name}</div>
                    {/* <div className="pax">
                      <i className="fab fa-ethereum"></i>0x59485…82590
                    </div> */}
                    {/* <div className="description">
                      8,888 NFTs of beautiful, Asian women painstakingly-crafted
                      where even the most intricate
                    </div> */}
                    {/* <ul className="social-item">
                      {fb && (
                        <li>
                          <a
                            href={`https://${fb}`}
                            rel="noreferrer"
                            target="_blank"
                          >
                            <i className="fab fa-facebook-f"></i>
                          </a>
                        </li>
                      )}
                      {twitter && (
                        <li>
                          <a
                            href={`https://${twitter}`}
                            rel="noreferrer"
                            target="_blank"
                          >
                            <i className="fab fa-twitter"></i>
                          </a>
                        </li>
                      )}
                      {discord && (
                        <li>
                          <a
                            href={`https://${discord}`}
                            rel="noreferrer"
                            target="_blank"
                          >
                            <i className="fab fa-discord"></i>
                          </a>
                        </li>
                      )}
                      {linkedin && (
                        <li>
                          <a
                            href={`https://${linkedin}`}
                            rel="noreferrer"
                            target="_blank"
                          >
                            <i className="fab fa-linkedin"></i>
                          </a>
                        </li>
                      )}
                      {medium && (
                        <li>
                          <a
                            href={`https://${medium}`}
                            rel="noreferrer"
                            target="_blank"
                          >
                            <i className="fab fa-medium"></i>
                          </a>
                        </li>
                      )}
                      {reddit && (
                        <li>
                          <a
                            href={`https://${reddit}`}
                            rel="noreferrer"
                            target="_blank"
                          >
                            <i className="fab fa-reddit"></i>
                          </a>
                        </li>
                      )}
                    </ul> */}
                  <div className="d-flex flexDirection-row justify-content-center align-items-center " style={{paddingTop:'1px'}}>
                    <p>Tokens :</p>
                    <p style={{fontSize:'17px',paddingLeft:'5px'}}>{tokenValue.toString()}</p>
                  </div>
                  </div>
                  <TabList className="filter-menuu menu-tab" style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'start'}}>
                    <Tab>
                      <a>
                        {" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            className="svg-fill"
                            d="M17.3722 6.47085C17.7995 7.0155 17.3282 7.70964 16.6359 7.70964H2.66602C2.11373 7.70964 1.66602 7.26192 1.66602 6.70964V5.3513C1.66602 3.31797 3.31602 1.66797 5.34935 1.66797H7.28268C8.64102 1.66797 9.06602 2.10964 9.60768 2.83464L10.7744 4.38464C11.0327 4.7263 11.066 4.76797 11.5493 4.76797H13.8744C15.2932 4.76797 16.5581 5.43348 17.3722 6.47085Z"
                            fill="#3749E9"
                          />
                          <path
                            className="svg-fill"
                            d="M17.3194 8.95704C17.8704 8.95704 18.3175 9.40269 18.3194 9.95365L18.3327 13.8739C18.3327 16.3323 16.3327 18.3323 13.8743 18.3323H6.12435C3.66602 18.3323 1.66602 16.3323 1.66602 13.8739V9.95724C1.66602 9.40496 2.11372 8.95725 2.666 8.95724L17.3194 8.95704Z"
                            fill="#3749E9"
                          />
                        </svg>{" "}
                        My Projects
                      </a>
                    </Tab>
                    <Tab>
                      <a>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            className="svg-fill"
                            d="M16.2586 4.8763L11.3086 2.01797C10.5003 1.5513 9.50026 1.5513 8.68359 2.01797L3.74193 4.8763C2.93359 5.34297 2.43359 6.20964 2.43359 7.1513V12.8513C2.43359 13.7846 2.93359 14.6513 3.74193 15.1263L8.69193 17.9846C9.50026 18.4513 10.5003 18.4513 11.3169 17.9846L16.2669 15.1263C17.0753 14.6596 17.5753 13.793 17.5753 12.8513V7.1513C17.5669 6.20964 17.0669 5.3513 16.2586 4.8763ZM10.0003 6.11797C11.0753 6.11797 11.9419 6.98464 11.9419 8.05964C11.9419 9.13464 11.0753 10.0013 10.0003 10.0013C8.92526 10.0013 8.05859 9.13464 8.05859 8.05964C8.05859 6.99297 8.92526 6.11797 10.0003 6.11797ZM12.2336 13.8846H7.76693C7.09193 13.8846 6.70026 13.1346 7.07526 12.5763C7.64193 11.7346 8.74193 11.168 10.0003 11.168C11.2586 11.168 12.3586 11.7346 12.9253 12.5763C13.3003 13.1263 12.9003 13.8846 12.2336 13.8846Z"
                            fill="#3749E9"
                          />
                        </svg>{" "}
                        Account Setting
                      </a>
                    </Tab>
                    <Tab>
                      <a>
                        {" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          className="svg-fill size-6"
                          width="20"
                          height="20"
                        >
                          <path
                            className="svg-fill"
                            d="M12 7.5a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5Z"
                          />
                          <path
                            className="svg-fill"
                            fillRule="evenodd"
                            d="M1.5 4.875C1.5 3.839 2.34 3 3.375 3h17.25c1.035 0 1.875.84 1.875 1.875v9.75c0 1.036-.84 1.875-1.875 1.875H3.375A1.875 1.875 0 0 1 1.5 14.625v-9.75ZM8.25 9.75a3.75 3.75 0 1 1 7.5 0 3.75 3.75 0 0 1-7.5 0ZM18.75 9a.75.75 0 0 0-.75.75v.008c0 .414.336.75.75.75h.008a.75.75 0 0 0 .75-.75V9.75a.75.75 0 0 0-.75-.75h-.008ZM4.5 9.75A.75.75 0 0 1 5.25 9h.008a.75.75 0 0 1 .75.75v.008a.75.75 0 0 1-.75.75H5.25a.75.75 0 0 1-.75-.75V9.75Z"
                            clipRule="evenodd"
                          />
                          <path
                            className="svg-fill"
                            d="M2.25 18a.75.75 0 0 0 0 1.5c5.4 0 10.63.722 15.6 2.075 1.19.324 2.4-.558 2.4-1.82V18.75a.75.75 0 0 0-.75-.75H2.25Z"
                          />
                        </svg>
                        Purchased Tickets
                      </a>
                    </Tab>
                    {/* <Tab>
                      <a>
                        {' '}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            className="svg-fill"
                            d="M17.3722 6.47085C17.7995 7.0155 17.3282 7.70964 16.6359 7.70964H2.66602C2.11373 7.70964 1.66602 7.26192 1.66602 6.70964V5.3513C1.66602 3.31797 3.31602 1.66797 5.34935 1.66797H7.28268C8.64102 1.66797 9.06602 2.10964 9.60768 2.83464L10.7744 4.38464C11.0327 4.7263 11.066 4.76797 11.5493 4.76797H13.8744C15.2932 4.76797 16.5581 5.43348 17.3722 6.47085Z"
                            fill="#3749E9"
                          />
                          <path
                            className="svg-fill"
                            d="M17.3194 8.95704C17.8704 8.95704 18.3175 9.40269 18.3194 9.95365L18.3327 13.8739C18.3327 16.3323 16.3327 18.3323 13.8743 18.3323H6.12435C3.66602 18.3323 1.66602 16.3323 1.66602 13.8739V9.95724C1.66602 9.40496 2.11372 8.95725 2.666 8.95724L17.3194 8.95704Z"
                            fill="#3749E9"
                          />
                        </svg>{' '}
                        Notifications
                      </a>
                    </Tab> */}
                    <Tab>
                      <a>
                        {" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          className="svg-fill size-6"
                          width="20"
                          height="20"
                        >
                          <path
                            className="svg-fill"
                            d="M4.5 3.75a3 3 0 0 0-3 3v.75h21v-.75a3 3 0 0 0-3-3h-15Z"
                          />
                          <path
                            className="svg-fill"
                            fillRule="evenodd"
                            d="M22.5 9.75h-21v7.5a3 3 0 0 0 3 3h15a3 3 0 0 0 3-3v-7.5Zm-18 3.75a.75.75 0 0 1 .75-.75h6a.75.75 0 0 1 0 1.5h-6a.75.75 0 0 1-.75-.75Zm.75 2.25a.75.75 0 0 0 0 1.5h3a.75.75 0 0 0 0-1.5h-3Z"
                            clipRule="evenodd"
                          />
                        </svg>
                        Private Sale Module
                      </a>
                    </Tab>
                    <Tab>
                      <a>
                        {" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          className="svg-fill size-6"
                          width="20"
                          height="20"
                        >
                          <path
                            className="svg-fill"
                            d="M5.85 3.5a.75.75 0 0 0-1.117-1 9.719 9.719 0 0 0-2.348 4.876.75.75 0 0 0 1.479.248A8.219 8.219 0 0 1 5.85 3.5ZM19.267 2.5a.75.75 0 1 0-1.118 1 8.22 8.22 0 0 1 1.987 4.124.75.75 0 0 0 1.48-.248A9.72 9.72 0 0 0 19.266 2.5Z"
                          />
                          <path
                            className="svg-fill"
                            fillRule="evenodd"
                            d="M12 2.25A6.75 6.75 0 0 0 5.25 9v.75a8.217 8.217 0 0 1-2.119 5.52.75.75 0 0 0 .298 1.206c1.544.57 3.16.99 4.831 1.243a3.75 3.75 0 1 0 7.48 0 24.583 24.583 0 0 0 4.83-1.244.75.75 0 0 0 .298-1.205 8.217 8.217 0 0 1-2.118-5.52V9A6.75 6.75 0 0 0 12 2.25ZM9.75 18c0-.034 0-.067.002-.1a25.05 25.05 0 0 0 4.496 0l.002.1a2.25 2.25 0 1 1-4.5 0Z"
                            clipRule="evenodd"
                          />
                        </svg>
                        Notifications
                      </a>
                    </Tab>
                    <Tab>
                      <a>
                        {" "}
                        <svg fill="#FFFF" className="svg-fill" stroke-width="0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"  height="20px" width="20px"><path fill="currentColor" d="M20 22H4a1 1 0 0 1-1-1V8h18v13a1 1 0 0 1-1 1Zm1-16H3V3a1 1 0 0 1 1-1h16a1 1 0 0 1 1 1v3ZM7 11v4h4v-4H7Zm0 6v2h10v-2H7Zm6-5v2h4v-2h-4Z"></path></svg>
                        Proposals
                      </a>
                    </Tab>
                    <Tab>
                      <a>
                        {" "}
                        {/* <?xml version="1.0" encoding="utf-8"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools --> */}
                        <svg fill="#FFFF" className="svg-fill"  stroke-width="2" xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-affiliate-filled" width="20px" height="20px" viewBox="0 0 24 24" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M18.5 3a2.5 2.5 0 1 1 -.912 4.828l-4.556 4.555a5.475 5.475 0 0 1 .936 3.714l2.624 .787a2.5 2.5 0 1 1 -.575 1.916l-2.623 -.788a5.5 5.5 0 0 1 -10.39 -2.29l-.004 -.222l.004 -.221a5.5 5.5 0 0 1 2.984 -4.673l-.788 -2.624a2.498 2.498 0 0 1 -2.194 -2.304l-.006 -.178l.005 -.164a2.5 2.5 0 1 1 4.111 2.071l.787 2.625a5.475 5.475 0 0 1 3.714 .936l4.555 -4.556a2.487 2.487 0 0 1 -.167 -.748l-.005 -.164l.005 -.164a2.5 2.5 0 0 1 2.495 -2.336z" stroke-width="0" fill="currentColor"></path></svg>                        Affiliates
                      </a>
                    </Tab>
                    <Tab>
                      <a>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 256 256"
                          width="20px"
                          height="20px"
                          fill="currentColor"
                        >
                          <g
                            fill="currentColor"
                            fillRule="nonzero"
                            stroke="none"
                            strokeWidth="1"
                            strokeLinecap="butt"
                            strokeLinejoin="miter"
                            strokeMiterlimit="10"
                            fontFamily="none"
                            fontWeight="none"
                            fontSize="none"
                            textAnchor="none"
                            style={{ mixBlendMode: "normal" }}
                          >
                            <g transform="scale(5.12,5.12)">
                              <path
                                className="svg-fill"
                                d="M25,5c-11.57422,0 -21,9.42578 -21,21c0,7.91406 4.39453,14.82422 10.87891,18.39844c0.625,0.35547 1.39453,0.34375 2.01172,-0.02344c0.62109,-0.37109 0.99219,-1.04687 0.97266,-1.76562c-0.01562,-0.71875 -0.42187,-1.375 -1.05469,-1.71484c-5.25,-2.89453 -8.80859,-8.46094 -8.80859,-14.89453c0,-9.41016 7.58984,-17 17,-17c9.41016,0 17,7.58984 17,17c0,6.43359 -3.55859,12 -8.80859,14.89453c-0.63281,0.33984 -1.03906,0.99609 -1.05469,1.71484c-0.01953,0.71875 0.35156,1.39453 0.97266,1.76563c0.61719,0.36719 1.38672,0.37891 2.01172,0.02344c6.48438,-3.57422 10.87891,-10.48437 10.87891,-18.39844c0,-11.57422 -9.42578,-21 -21,-21zM25,11c-8.26172,0 -15,6.73828 -15,15c0,5.64844 3.14063,10.58594 7.76953,13.14063c0.62891,0.37109 1.41016,0.37109 2.03906,0.00391c0.62891,-0.37109 1.00391,-1.05469 0.98047,-1.78516c-0.01953,-0.73047 -0.4375,-1.39062 -1.08594,-1.71875c-3.39844,-1.875 -5.70312,-5.47266 -5.70312,-9.64062c0,-6.09766 4.90234,-11 11,-11c6.09766,0 11,4.90234 11,11c0,4.16797 -2.30469,7.76563 -5.70312,9.64063c-0.64844,0.32813 -1.06641,0.98828 -1.08594,1.71875c-0.02344,0.73047 0.35156,1.41406 0.98047,1.78516c0.62891,0.36719 1.41016,0.36719 2.03906,-0.00391c4.62891,-2.55469 7.76953,-7.49219 7.76953,-13.14062c0,-8.26172 -6.73828,-15 -15,-15zM25,17c-4.94531,0 -9,4.05469 -9,9c0,3.38281 1.88281,6.35156 4.66016,7.88672c0.62891,0.35156 1.39844,0.34375 2.01563,-0.02734c0.61719,-0.37109 0.98828,-1.04297 0.97266,-1.76172c-0.01953,-0.72266 -0.42187,-1.375 -1.05469,-1.71875c-1.54687,-0.84766 -2.59375,-2.47656 -2.59375,-4.37891c0,-2.78516 2.21484,-5 5,-5c2.78516,0 5,2.21484 5,5c0,1.90234 -1.04687,3.53125 -2.59375,4.37891c-0.63281,0.34375 -1.03516,0.99609 -1.05469,1.71875c-0.01562,0.71875 0.35547,1.39063 0.97266,1.76172c0.61719,0.37109 1.38672,0.37891 2.01563,0.02734c2.77734,-1.53516 4.66016,-4.50391 4.66016,-7.88672c0,-4.94531 -4.05469,-9 -9,-9zM25,23c-1.65625,0 -3,1.34375 -3,3c0,1.65625 1.34375,3 3,3c1.65625,0 3,-1.34375 3,-3c0,-1.65625 -1.34375,-3 -3,-3z"
                              ></path>
                            </g>
                          </g>
                        </svg>
                        Air Drop
                      </a>
                    </Tab>
                  </TabList>
                </div>
              </div>
              <div className="col-xl-9 col-lg-12 col-md-12 overflow-table">
                <div className="dashboard-content inventory content-tab">
                  <TabPanel>
                    <div className="inner-content inventory">
                      <h4 className="title-dashboard d-flex justify-content-between">
                        <div>My Projects</div>
                        <button
                          onClick={() =>
                            navigate(isAuthenticated ? "/create" : "/login")
                          }
                        >
                          Create Project
                        </button>
                      </h4>
                      <div className="table-ranking ">
                        {projects.length !== 0
                          ? projects?.map((t, i) => (
                              <div className="content-ranking">
                                <div className="col-rankingg">
                                  <div className="image">
                                    <img
                                      src={`${global.imageURL}/nodeassets/${t.project_iconImage}`}
                                      style={{ width: "100%", height: "60px" }}
                                      alt="ANTS"
                                    />
                                  </div>
                                </div>
                                <div
                                  className="col-rankingg"
                                  onClick={() =>
                                    navigate(
                                      `/project-detail/${t.project_slug}`
                                    )
                                  }
                                  style={{ cursor: "pointer" }}
                                >
                                  {t.project_name}
                                </div>
                                <div className="col-rankingg coin">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="23"
                                    height="22"
                                    viewBox="0 0 23 22"
                                    fill="none"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M11.7619 0V8.13257L5.74624 10.8216L11.7619 8.13418V15.1949L4.89084 11.204L4.89062 11.2041L4.8907 11.2039L4.89062 11.2038L4.89077 11.2038L11.7619 0ZM11.7637 0L18.6357 11.2038L18.6359 11.2038L18.6359 11.2039L18.6359 11.2041L18.6357 11.204L11.7637 15.1949V8.13418L17.7802 10.8216L11.7637 8.13257V0ZM11.7626 16.4746V22.0005L4.88672 12.4844L11.7626 16.4746ZM11.7637 22.0005V16.4736L18.6359 12.4844L11.7637 22.0005Z"
                                      fill="#6B8CEF"
                                    />
                                  </svg>
                                  Ethereum
                                </div>
                                <div className="col-rankingg">{t.hm_alreadyRaised} raised</div>
                                <div className="col-rankingg">{t.how_much_want_raise}</div>
                                <div className="col-rankingg nofication">
                                  {" "}
                                  <i className="fas fa-circle"></i>{" "}
                                  {t.project_status}
                                </div>
                                <div className="col-rankingg dot relative">
                                  <div
                                    onClick={() => {
                                      setLinkClick((prev) => !prev);
                                      setProductId(t.project_id);
                                    }}
                                  >
                                    {linkClick &&
                                      t.project_id === productId && (
                                        <div className="updatePLinkAbsolute">
                                          <Link
                                            to={`/update-project/${t.project_id}`}
                                          >
                                            Edit
                                          </Link>
                                          <nav
                                            className="deletePLink"
                                            onClick={() =>
                                              handleDelete(t.project_id)
                                            }
                                          >
                                            Delete
                                          </nav>
                                        </div>
                                      )}
                                    <i className="fas fa-ellipsis-h"></i>
                                  </div>
                                </div>
                              </div>
                            ))
                          : "You don't have Projects created yet"}
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className="inner-content profile">
                      <h4 className="title-dashboard">Your Profile</h4>
                      <AccountSetting data={data[0]} fetchData={fetchData} />
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className="inner-content inventory">
                      <h4 className="title-dashboard d-flex justify-content-between">
                        <div>Purchased Tickets</div>
                        <button
                          onClick={() =>
                            navigate(isAuthenticated ? "/governance" : "/login")
                          }
                        >
                          Purchase a new ticekt
                        </button>
                      </h4>
                      <div className="table-ranking ">
                        {purchasedTickets.length !== 0
                          ? purchasedTickets?.map((t, i) => (
                              <div className="content-ranking">
                                <div
                                  className="col-rankingg"
                                  style={{ cursor: "pointer" }}
                                >
                                  {t.ticket_name}
                                </div>
                                <div className="col-rankingg">
                                  ANTS Tokens: {t.ticket_purchase_weightage}
                                </div>
                              </div>
                            ))
                          : "You don't have Tickets created yet"}
                      </div>
                    </div>
                  </TabPanel>
                  {/* <TabPanel>
                    <div className="inner-content profile">

                      <div className="form-edit-profile">
                        <div className="user-profile">
                          <div className="title ">Notifications details</div>
                          <table className="rounded">
                            <thead>
                              <tr>
                                <th >Title</th>
                                <th >Description</th>
                                <th >
                                  User
                                </th>
                                
                              </tr>
                            </thead>
                            <tbody>
                            {filteredNotifications && filteredNotifications.length > 0 ? (
                              filteredNotifications.map((notifi, index) => (
                                  <tr key={index}>
                                    <td>{notifi.notification_title}</td>
                                    <td>{notifi.notification_description}</td>
                                    <td>{notifi.notification_user_name}</td>
                                  </tr>
                                ))
                              ) : (
                                <p className='noProposal'>Notification not availabel</p>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </TabPanel> */}
                  <TabPanel>
                  <div className="inner-content profile">
  <div className="form-edit-profile">
    <div className="user-profile">
      <div className="title">Private Sale Module</div>
      {formattedSaleData.length > 0 ? (
        <table
          style={{
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
            overflow: "hidden",
          }}
        >
          <thead
            style={{
              backgroundColor: "#1F1F2C",
              color: "#ffffff",
            }}
          >
            <tr
              style={{
                border: "0",
              }}
            >
              <th className="text-white">Start Time</th>
              <th className="text-white">End Time</th>
              <th className="text-white">Token Price</th>
              <th className="text-white">Total Token</th>
              <th className="text-white">Status</th>
              <th className="text-white">Action</th>
            </tr>
          </thead>
          <tbody>
            {formattedSaleData.map((data) => (
              <tr
                key={data.id}
                style={{
                  borderBottom: "1px solid #d1d5db",
                  transition: "background-color 0.3s ease",
                }}
              >
                <td>{data.private_sale_start_time_formatted}</td>
                <td>{data.private_sale_end_time_formatted}</td>
                <td style={{ wordBreak: "break-word", overflow: "hidden" }}>{data.private_sale_token_price} wei</td>
                <td>{data.private_total_token}</td>
                <td>{data.private_sale_status}</td>
                <td className="button">
                  <button
                    onClick={(e)=>{
                      handleNavigateToPrivateSale(data)
                    }}
                    style={{
                      paddingRight: "10px",
                      paddingLeft: "10px",
                      paddingTop: "5px",
                      paddingBottom: "5px",
                      borderRadius: "5px",
                      fontWeight: "400",
                      display: "flex",
                      alignItems: "center",
                    }}
                    className="tf-button"
                  >
                    <span>More Details</span>
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-chevron-right"
                      >
                        <polyline points="9 18 15 12 9 6"></polyline>
                      </svg>
                    </span>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div
          style={{
            textAlign: "center",
            padding: "20px",
            fontSize: "16px",
            color: "#888",
          }}
        >
          No private sale exists.
        </div>
      )}
    </div>
  </div>
</div>

                  </TabPanel>
                  <TabPanel>
                    <div className="inner-content profile">
                      <div className="form-edit-profile">
                        <div className="user-profile">
                          <div className="title">Notifications details</div>

                          {/* Conditional Rendering: Only show table if there are notifications */}
                          {filteredNotifications &&
                          filteredNotifications.length > 0 ? (
                            <table className="rounded">
                              <thead>
                                <tr>
                                  <th>Title</th>
                                  <th>Description</th>
                                  <th>User</th>
                                </tr>
                              </thead>
                              <tbody>
                                {filteredNotifications.map((notifi, index) => (
                                  <tr key={index}>
                                    <td>{notifi.notification_title}</td>
                                    <td>{notifi.notification_description}</td>
                                    <td>{notifi.notification_user_name}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          ) : (
                            // Display message if no notifications available
                            <p className="noProposal text-center" style={{color:'#888'}}>
                              No notifications available
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className="inner-content profile">
                      <div className="form-edit-profile">
                        <div className="user-profile">
                          <h4 className="title-dashboard d-flex justify-content-between">
                            <div className="title">Proposals</div>
                            <button
                              onClick={() =>
                                navigate(
                                  isAuthenticated ? "/createProposal" : "/login"
                                )
                              }
                            >
                              Create Proposal
                            </button>
                          </h4>
                          <table className="rounded">
                            <thead>
                              <tr>
                                <th>Title</th>
                                <th>Description</th>
                                <th>Start (Date and Time)</th>
                                <th>End (Date and Time)</th>
                                <th>View</th>
                              </tr>
                            </thead>
                            <tbody>
                            {formattedProposalData && formattedProposalData.length > 0 ? (
        formattedProposalData.map((proposal, index) => (
          <tr key={index}>
            <td>{proposal.proposal_title}</td>
            <td>{proposal.proposal_description}</td>
            <td>{proposal.proposal_start_formatted}</td>
            <td>{proposal.proposal_end_formatted}</td>
                                    <td
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <button
                                        style={{
                                          width: "85px",
                                          height: "50px",
                                          textAlign: "center",
                                          padding: 0,
                                          marginTop: "10px",
                                          marginBottom: "10px",
                                        }}
                                        onClick={() =>
                                          navigate("/proposal-detail", {
                                            state: proposal,
                                          })
                                        }
                                      >
                                        View
                                      </button>
                                      {proposal.proposal_user_id == userUid && (
                                        <button
                                          onClick={() =>
                                            navigate("/createProposal", {
                                              state: { dataObj: proposal },
                                            })
                                          }
                                          style={{
                                            width: "85px",
                                            height: "50px",
                                            textAlign: "center",
                                            padding: 0,
                                            marginTop: "10px",
                                            marginBottom: "10px",
                                          }}
                                        >
                                          Edit
                                        </button>
                                      )}
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <p className="noProposal">
                                  No proposals available.
                                </p>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className="inner-content profile">
                      <div className="form-edit-profile">
                        <div className="user-profile">
                          <h4 className="title-dashboard d-flex justify-content-between">
                            <div className="title">Affiliated User</div>
                            <button onClick={handleShareLink}>
                              Share link
                            </button>
                          </h4>
                          {affiliatedUsers && affiliatedUsers.length > 0 ? (
                            <table className="rounded">
                              <thead>
                                <tr>
                                  <th>User</th>
                                </tr>
                              </thead>
                              <tbody>
                                {affiliatedUsers.map((notifi, index) => (
                                  <tr key={index}>
                                    <td>{notifi.wallet_address}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          ) : (
                            <p className="noProposal text-center"  style={{color:'#888'}}>
                              No affiliates available
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className="inner-content profile">
                      <div className="form-edit-profile">
                        <div className="user-profile">
                          <h4 className="title-dashboard d-flex justify-content-between">
                            <div className="title">Air Drop</div>
                          </h4>
                          {airDropTokens && airDropTokens.length > 0 ? (
                            <table
                              style={{
                                borderTopLeftRadius: "10px",
                                borderTopRightRadius: "10px",
                                overflow: "hidden",
                              }}
                            >
                              <thead
                                style={{
                                  backgroundColor: "#1F1F2C",
                                  color: "#ffffff",
                                }}
                              >
                                <tr
                                  style={{
                                    border: "0",
                                  }}
                                >
                                  <th className="text-white">
                                    Number of Tokens
                                  </th>
                                  <th className="text-white">
                                    Lock Time Period
                                  </th>
                                  <th className="text-white">Status</th>
                                </tr>
                              </thead>
                              <tbody>
                                {airDropTokens.map((data) => {
                                  const createdAt = new Date(data.created_at);
                                  const claimableDate = new Date(
                                    createdAt.setDate(
                                      createdAt.getDate() + data.duration_days
                                    )
                                  );
                                  const isClaimable =
                                    new Date() >= claimableDate;
                                  return (
                                    <tr
                                      key={data.airdrop_id}
                                      style={{
                                        borderBottom: "1px solid #d1d5db",
                                        transition:
                                          "background-color 0.3s ease",
                                      }}
                                    >
                                      <td>{data.token_count}</td>
                                      <td>{data.duration_days} days</td>
                                      <td className="button">
                                        {isClaimable ? (
                                          <button
                                            onClick={() =>
                                              handleClaim(data.airdrop_id)
                                            }
                                            disabled={data.status === "claimed"}
                                            style={{
                                              paddingRight: "10px",
                                              paddingLeft: "10px",
                                              paddingTop: "5px",
                                              paddingBottom: "5px",
                                              borderRadius: "5px",
                                              fontWeight: "400",
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                            className="tf-button"
                                          >
                                            <span>
                                              {data.status === "claimed"
                                                ? "Claimed"
                                                : "Claim"}
                                            </span>
                                            <span>
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="20"
                                                height="20"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                className="feather feather-chevron-right"
                                              >
                                                <polyline points="9 18 15 12 9 6"></polyline>
                                              </svg>
                                            </span>
                                          </button>
                                        ) : (
                                          <svg
                                            style={{
                                              width: "35px",
                                              textAlign: "center",
                                            }}
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke-width="0.5"
                                            stroke="currentColor"
                                            class="size-1"
                                          >
                                            <path
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                              d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z"
                                            />
                                          </svg>
                                        )}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          ) : (
                            <p className="noProposal text-center">
                              No Air Drop available
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                </div>
              </div>
            </div>
          </Tabs>
        </div>
      </section>
    </div>
  );
}

export default Dashboard;
