import React, { useState, useEffect } from "react";
import PageTitle from "../components/pagetitle/PageTitle";
import { Link, useLocation } from "react-router-dom";
import icon3 from "../assets/images/icon/ethe.svg";

import { useParams } from "react-router-dom";
import axios from "axios";
import "../css/ItemDetails01.css";
import { truncate } from "lodash";
import { useStateContext } from "../components/context/AppContext";
import { useNavigate } from "react-router-dom";
import NgoModal from "../components/layouts/NgoModal";
import ProfitableModal from "../components/layouts/ProfitableModal";
import StackModel from "../components/stackModel/StackModel";
import { useDashboard } from "../hooks/dashboard";
import { donateStaking } from "../api/Api";
import { toast } from "react-toastify";
import Web3 from "web3";
import ABIStaking from "../utils/abi-staking";
import {default as ABI } from '../utils/abi'
import { contractAddress, INFURA_URL, OWNER_WALLET_ADDRESS, stakingContractAddress, WALLET_PRIVATE_KEY } from "../utils/utils";
import { usePools } from "../hooks/UsePools";

export default function PoolDetail() {
  const navigate = useNavigate();
  const { userUid } = useDashboard();
  const location = useLocation();
  const proposal = location.state;

  const { isAuthenticated } = useStateContext();
  const { slug } = useParams();
  const [data, setdata] = useState([]);
  const [data2, setdata2] = useState([]);
  const [data3, setdata3] = useState([]);
  const [projects, setprojects] = useState([]);
  const [mulitImages, setmulitImages] = useState([]);
  const [uid, setuid] = useState("");
  const [donationType, setdonationType] = useState("");

  const [votes, setVotes] = useState([]);
  const [userVotes, setUserVotes] = useState([]);
  const [votingPowerTokens, setVotingPowerTokens] = useState(0);
  const [enteredAmount, setEnteredAmount] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [proposalData, setProposalData] = useState(null);

  const [allDonationList, setAllDonationList] = useState([]);
  const {dataAPI,getData,loading}=usePools()

  useEffect(() => {
    // First, check if data is in location state
    if (location.state && location.state.dataObj) {
      setProposalData(location.state)
    
    }})
  const fetchProducts = () => {
    axios
      .get(`${global.backendUrl}/project/get`)
      .then((res) => {
        setprojects(res.data);
        const filteredData = res.data.filter((t) => t.project_slug == slug);
        setdata(filteredData);
        setmulitImages(JSON.parse(filteredData[0].project_sliderImages));

        axios
          .get(`${global.backendUrl}/donation/get`)
          .then((response) => {
            const filter = response.data.filter(
              (t) => t.project_id == filteredData[0].project_id
            );
            setdata3(filter);
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };

    useEffect(() => {
      getData();
    }, []);
    const currentPoolId = proposal?.dataObj?.staking_id;
  
    const activeProposals = dataAPI.filter((proposal) => proposal.staking_status === "Active");
    const relatedProposals = activeProposals.filter(
      (pool) => pool.staking_id !== currentPoolId
    );
  const fetchUsers = () => {
    const AccountInfo = localStorage.getItem("antsInformation");
    const parsedInfo = JSON.parse(AccountInfo);
    setuid(parsedInfo?.uid);

    axios
      .get(`${global.backendUrl}/signup/get`)
      .then((res) => {
        setdata2(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchProducts();
    fetchUsers();
  }, [slug]);
  useEffect(() => {
    fetchVotesUserProject();
    fetchVotesUser();
    fetchVotingPower();
  }, [data, uid]);

  const fetchDonations = () => {
    axios
      .get(
        `${global.backendUrl}/staking/donations/${proposal.dataObj.staking_id}`,
        {
          params: { user_id: userUid },
        }
      )
      .then((res) => {
        setAllDonationList(res.data);
      })
      .catch((err) => {
        console.error("Error fetching donations:", err);
      });
  };

  useEffect(() => {
    fetchDonations();
  }, [userUid]);
  const fetchVotesUserProject = () => {
    if (uid && data.length > 0) {
      axios
        .get(`${global.backendUrl}/vote/project/${data[0].project_id}/${uid}`)
        .then((res) => {
          setVotes(res.data);
        })
        .catch((err) => console.log(err));
    }
  };
  const fetchVotesUser = () => {
    if (uid && data.length > 0) {
      axios
        .get(`${global.backendUrl}/vote/project/user/${uid}`)
        .then((res) => {
          setUserVotes(res.data);
        })
        .catch((err) => console.log(err));
    }
  };
  const fetchVotingPower = () => {
    axios
      .get(`${global.backendUrl}/votingPower`)
      .then((res) => {
        setVotingPowerTokens(res.data[0].voting_power_tokens);
      })
      .catch((err) => console.log(err));
  };

  const handleModalConfirm = async (amount) => {
    if (!amount) {
      toast.error("Please enter a valid amount");
      return;
    }
  
    try {
      
      const formData = {
        user_id: userUid,
        stake_id: proposal.dataObj.staking_id,
        donation_amount: amount,
      };
  
      const res = await donateStaking(formData);
      if (res.status === 200) {
        const newDonation = {
          donation_amount: amount,
          donation_time: new Date().toISOString(),
          staking_apy: proposal?.dataObj?.staking_apy,
        };
  
        setAllDonationList((prevList) => [newDonation, ...prevList]);
        setEnteredAmount(null);
      } else {
        toast.error("Error occurred in adding donation");
        return;  
      }
    } catch (error) {
      console.error("Error during donation:", error);
      toast.error("Failed to process the donation");
      return;  
    }
  
    // Approve staking
    try {
      const web3 = new Web3(new Web3.providers.HttpProvider(INFURA_URL));
      const privateKey = WALLET_PRIVATE_KEY;
      const accounts = await web3.eth.getAccounts();
      const account = accounts[0];
      const walletAddress = OWNER_WALLET_ADDRESS;
  
      const contract = new web3.eth.Contract(ABI, contractAddress);
      const tx = contract.methods.approveStaking(stakingContractAddress, amount);
      const gas = await tx.estimateGas({ from: walletAddress });
      const gasPrice = await web3.eth.getGasPrice();
  
      const txData = {
        from: walletAddress,
        to: contractAddress,
        data: tx.encodeABI(),
        gas,
        gasPrice,
      };
  
      const signedTx = await web3.eth.accounts.signTransaction(txData, privateKey);
      const receipt = await web3.eth.sendSignedTransaction(signedTx.rawTransaction);
  
      console.log("Approve staking successful:", receipt);
    } catch (error) {
      console.error("Error during staking approval:", error);
      toast.error("Failed to approve staking");
      return;  
    }
  
    // Transaction logic
    try {
      const web3 = new Web3(window.ethereum);
      await window.ethereum.enable();
      const accounts = await web3.eth.getAccounts();
      const account = accounts[0];
      const contract = new web3.eth.Contract(ABIStaking, stakingContractAddress);
  
      const gasPrice = await web3.eth.getGasPrice();
  
      await contract.methods
        .stake(proposal.dataObj.staking_id, amount)
        .send({
          from: account,
          gas: 2000000,
          gasPrice: gasPrice,
          value: 0,
        })
        .then((result) => {
          toast.success(`You staked ${amount} tokens successfully`);
          setModalShow(false);
          console.log("Transaction successful:", result);
        })
        .catch((error) => {
          console.error("Transaction failed:", error);
          toast.error("Transaction failed");
        });
    } catch (error) {
      console.error("Error in blockchain interaction:", error);
      toast.error("Error during transaction");
    }
  };
  

  const handleStakeClick = () => {
    setEnteredAmount(null);
    setModalShow(true);
  };
  const handleClaim = async (donationId) => {
    try {
      const res = await axios.post(`${global.backendUrl}/claim-donation`, {
        donation_id: donationId,
      });
    
    } catch (error) {
      console.error("Error claiming donation:", error);
    }

    try {
      const web3 = new Web3(window.ethereum);
      await window.ethereum.enable();
      const accounts = await web3.eth.getAccounts();
      const account = accounts[0];
      let contract = new web3.eth.Contract(ABIStaking, stakingContractAddress);
      console.log(contract.methods);
  
      const gasPrice = await web3.eth.getGasPrice();
      console.log("gasPrice", gasPrice);
  
      await  contract.methods
      .claimStake(donationId) 
      .send({
          from: account,
          gas: 2000000,
          gasPrice: gasPrice,
          value: 0,
        })
        .then((result) => {
          console.log("Transaction successful:", result);
        })
        .catch((error) => {
          console.error("Transaction failed:", error);
        });
    } catch (error) {
      console.error("Error in blockchain interaction:", error);
    }
  };
  
  console.log(proposalData)
  const customStyle = {
    color: 'var(--primary-color5)',
    display:'flex',
    flexDirection:'row',
    paddingRight:'10px'
    // Applying custom color
  };
  return (
    <>
      <PageTitle sub="Pools" title="Pools Details" />

      <div className=" topMain">
        <div className="tf-item-detail-inner ss">
          <div className="content">
            <div className="main">
              <div className="first">
                <h4 style={customStyle}>
                  {" "}
                  {proposalData?.dataObj?.staking_title}
                </h4>
                <div style={customStyle}>
                  <p style={customStyle}>Stak APY:</p> <p>
                  {proposalData?.dataObj?.staking_apy}
                  </p>
                </div>
                <p style={customStyle}>
                  <p style={customStyle}>Stak Period:</p><p>
                  {proposalData?.dataObj?.staking_period}

                  </p>
                </p>
                <p style={customStyle}>
                  <p style={customStyle}>Stak Pool size:</p>
<p>
{proposalData?.dataObj?.staking_pool_size}

</p>
                </p>
                <p className="text-white"></p>
                <p className="text-white"></p>
              </div>
            </div>
          </div>
        </div>
        <div className="tf-item-detail-inner ss">
          <div className="content">
            <div className="main">
              <div className="second">
                <p
             
                  style={{ display: "flex", flexDirection: "column",customStyle }}
                >
                  <p style={customStyle}>Description:</p>{" "}
                  {proposalData?.dataObj?.staking_description}
                </p>
                <p style={customStyle}>
                  <p>Stake</p>
                </p>
                <div className="content-bottom">
                  <div className="button">
                    <div
                      onClick={handleStakeClick}
                      className="tf-button"
                      data-toggle="modal"
                      data-target="#popup_bid"
                    >
                      Stake
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {allDonationList.length>0 ? 
      <div className="topMain">
        <div className="tf-item-detail-inner ss">
          <div className="content">
            <div className="main">
              <div className="first">
                
                <table className="donation-table">
                  <thead>
                    <tr>
                      <th>Donation Amount</th>
                      <th>Donation Time</th>
                      <th>Staking APY</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allDonationList.map((donation, index) => {
                      const donationDate = new Date(donation.donation_time);
                      const currentDate = new Date();
                      const daysPassed = Math.floor(
                        (currentDate - donationDate) / (1000 * 60 * 60 * 24)
                      );
                      return (
                        <tr key={index}>
                          <td>{donation.donation_amount}</td>
                          <td>{donationDate.toLocaleString()}</td>
                          <td>{donation.staking_apy}</td>
                          <td>
                            {daysPassed >= 30 ? (
                              <button
                              disabled={donation.status === "claimed"}
                              onClick={() => handleClaim(donation.donate_stake_id)}
                                style={{
                                  paddingRight: "30px",
                                  paddingLeft: "30px",
                                  paddingTop: "5px",
                                  paddingBottom: "5px",
                                  borderRadius: "5px",
                                  fontWeight: "400",
                                  display: "flex",
                                  alignItems: "center",
                                  cursor:donation.status==='claimed'?'not-allowed':''
                                }}
                                className="tf-button "
                              >
                                <span>{donation.status === "claimed" ? "Claimed" : "Claim"}</span>
                                <span>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="feather feather-chevron-right"
                                  >
                                    <polyline points="9 18 15 12 9 6"></polyline>
                                  </svg>
                                </span>
                              </button>
                            ) : (
                              <svg
                                style={{ width: "35px", textAlign: "center" }}
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="0.5"
                                stroke="currentColor"
                                class="size-1"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z"
                                />
                              </svg>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                
              </div>
            </div>
          </div>
        </div>
      </div>
      : null }
      <section className="tf-explore-more">
        <div className="tf-container">
          <div className="row">
            <div className="col-md-12">
              <div className="tf-heading">
                <h4 className="heading">Related Pool</h4>
              </div>
            </div>

             <div className="col-md-9 ">
                         <div className="top-option">
                         </div>
                         <div className="tf-explore-sidebar-wrap">
             {relatedProposals && relatedProposals.length > 0 ? (
               relatedProposals.map((proposal, index) => (
                 <div key={proposal.id} className="sc-product style2" style={{display:'flex',flexDirection:'column',justifyContent:'space-between'}}>
                  <div>
                  <div className="top" onClick={()=>navigate('/pool-detail',{
                                                                 state: { dataObj: proposal }
           
                   })}>
                    <Link   className="tag">
                       {proposal.staking_title}
                     </Link> 
                   </div>
                   <div className="features" style={{display:'flex',justifyContent:'space-between',marginTop:'20px'}}>
                     <p style={{fontWeight:'bold',customStyle}}>Stak APY</p>
                     {
                       proposal.staking_apy
                     }
                   </div>
                   <div className="features" style={{display:'flex',justifyContent:'space-between',marginTop:'20px'}}>
                     <p style={{fontWeight:'bold',customStyle}}>Stak Period</p>
                     {
                       proposal.staking_period
                     }
                   </div>
                   <div className="features" style={{display:'flex',justifyContent:'space-between',marginTop:'20px'}}>
                     <p style={{fontWeight:'bold',customStyle}}>Pool Size</p>
                     {
                       proposal.staking_pool_size
                     }
                   </div>
                   <div className="features" style={{marginTop:'20px'}}>
                     <h6>Description</h6>
                     {
                       proposal.staking_description
                     }
                   </div>
                  </div>
                   <div className="bottom-style2">
                     <div className="product-button" 
                     onClick={()=>navigate('/pool-detail',{
                       state: { dataObj: proposal }
           
           })}
                     >
                       <Link
                         data-toggle="modal"
                         data-target="#popup_bid"
                         className="tf-button"
                       >
                         Stake
                       </Link>
                     </div>
                   </div>
                 </div>
               ))
             ) : (
              <div className="noProposal">
                <p className='noProposal container' style={{textAlign:'center',marginRight:'40px'}}>No pools available.</p>
              </div>
             )}
           </div>
           
                    
                       </div>
          </div>
        </div>

        {/* <CardModal show={modalShow} onHide={() => setModalShow(false)} /> */}
      </section>

      {data[0]?.project_type == "NGO" ? (
        <NgoModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          projectId={data[0]?.project_id}
          projectName={data[0]?.project_name}
          userId={uid}
          donationType={donationType}
          wallet_address={data.length > 0 ? data[0].wallet_address : ""}
          user_email={data.length > 0 ? data[0].email : ""}
        />
      ) : (
        <ProfitableModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          projectId={data[0]?.project_id}
          projectName={data[0]?.project_name}
          userId={uid}
          amountWantRaise={data[0]?.hm_lookingRaiseWithAnts}
          donationType={donationType}
          wallet_address={data.length > 0 ? data[0].wallet_address : ""}
          user_email={data.length > 0 ? data[0].email : ""}
        />
      )}
      <StackModel
        show={modalShow}
        onHide={() => setModalShow(false)}
        onConfirm={handleModalConfirm}
      />
    </>
  );
}
