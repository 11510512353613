import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import PageTitle from '../components/pagetitle/PageTitle';
import data from '../assets/fake-data/data-blog';
import { bloguser } from '../utils/Assets';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import { WebsiteUrl } from '../App';
import { truncate } from 'lodash';
import '../css/Blog02.css';
import { toast } from 'react-toastify';
Blog02.propTypes = {};

function Blog02(props) {
  const [data, setdata] = useState([]);
  const [filteredData, setfilteredData] = useState([]);
  const [data2, setdata2] = useState([]);
  const [data3, setdata3] = useState([]);
  const [activePage, setActivePage] = useState(1);
    const [isLoading, setisLoading] = useState(false);
    const [email, setemail] = useState('');

  const [currentPage, setCurrentPage] = useState(1);
  const location = useLocation();


  const [categoryChecker, setcategoryChecker] = useState('');

  const [loading, setLoading] = useState(true); 

  const postsPerPage = 3;

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  // const currentPosts = data.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    setActivePage(pageNumber);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'  
    });
  };
  

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant'
    });
  }, [location.pathname]);

  useEffect(() => {
    setLoading(true);

    axios
      .get(`${global.backendUrl}/blog/get`)
      .then((response) => {
        setdata(response.data);
        setfilteredData(response.data);
    setLoading(false);

      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${global.backendUrl}/homeBlogSco/get`)
      .then((response) => {
        setdata2(response.data);
    setLoading(false);

      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${global.backendUrl}/blogCategory/get`)
      .then((response) => {
        setdata3(response.data);
    setLoading(false);

      })
      .catch((err) => {
        console.log(err);
      });
      
  }, []);

  const [dataRecent] = useState([
    {
      id: 1,
      img: bloguser,
      title: 'Best Corporate Tips You Will Read This Year.',
      cate: 'Music NFT’s',
    },
    {
      id: 2,
      img: bloguser,
      title: 'Should Fixing Corporate Take 100 Steps.',
      cate: 'Music NFT’s',
    },
    {
      id: 3,
      img: bloguser,
      title: 'The Next 100 Things To Immediately Do About',
      cate: 'Music NFT’s',
    },
    {
      id: 4,
      img: bloguser,
      title: 'Top 5 Lessons About Corporate To Learn',
      cate: 'Music NFT’s',
    },
  ]);

  // const [showAll, setShowAll] = useState(false);
  // const initialCount = 3;

  // const toggleShowAll = () => {
  //   setShowAll(!showAll);
  // };

  useEffect(() => {
    const newFilterData = data.filter(
      (t) => t.blog_category == categoryChecker
    );
    setfilteredData(newFilterData);
  }, [categoryChecker]);


  
  const customStyle = {
 fontSize:'18px'
  };
  function stripHTML(html) {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // const handleJoin = () => {
  //   if (!email) {
  //     return toast.error(`Please Enter The Email`, {
  //       position: 'top-right',
  //       autoClose: 3000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //     });
  //   }

  //   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  //   if (!emailRegex.test(email)) {
  //     return toast.error(`Please enter a valid email address.`, {
  //       position: 'top-right',
  //       autoClose: 3000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //     });
  //   }

  //   const filteredEmail = data.filter((t) => t.email === email);

  //   if (filteredEmail.length == 0) {
  //     setisLoading(true);
  //     const formData = { email };
  //     axios
  //       .post(`${global.backendUrl}/subscriber/insert`, formData)
  //       .then((response) => {
  //         toast.success(`Your are Joined Successfully !`, {
  //           position: 'top-right',
  //           autoClose: 3000,
  //           hideProgressBar: false,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //           progress: undefined,
  //         });
  //         setisLoading(false);
  //         setemail('');
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   } else {
  //     toast.error(`This Email Already Exists`, {
  //       position: 'top-right',
  //       autoClose: 3000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //     });
  //   }
  // };

  const handleJoin = async () => {
    if (!email) {
          return toast.error(`Please Enter The Email`, {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
    
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    
        if (!emailRegex.test(email)) {
          return toast.error(`Please enter a valid email address.`, {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
    try {
      const subscribeResponse = await axios.post(`${global.backendUrl}/subscriber/insert`, {
        email: email  
      });
  
      if (subscribeResponse.data.success) {
        
        const templateResponse = await axios.get(`${global.backendUrl}/email_templates`);
        const templates = templateResponse.data;
        
       
        const newsletterTemplate = templates.find(temp => temp.email_template_type === 'News Letter');
        
        if (newsletterTemplate) {
           
          const formDataEmail = {
            user_email: email,
            subject: newsletterTemplate.email_template_subject,
            message: newsletterTemplate.email_template_content
          };
  
           
          await axios.post(`${global.backendUrl}/sendEmail`, formDataEmail);
          
        
          toast.success('Subscribed and welcome email sent successfully!');
        } else {
          toast.success('Subscribed successfully!');
          console.log('Email not sent');
        }
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error("Error occure during subscription");
    }
  };
  return (
    <>
      {data2[0] && (
        <Helmet>
          <title>{data2[0]?.meta_title}</title>

          <meta name="description" content={data2[0]?.meta_description} />
          <meta name="keywords" content={JSON.parse(data2[0]?.meta_keywords)} />
          <link rel="canonical" href={`${WebsiteUrl}`} />
          {/* <link
              rel="canonical"
              href={`${WebsiteUrl}`}
            /> */}
          <meta name="robots" content="index,follow" />
          <meta property="og:title" content={data2[0]?.meta_title} />
          <meta
            property="og:description"
            content={data2[0]?.meta_description}
          />
          <meta
            property="og:image"
            content={`${global.imageURL}/nodeassets/${data2[0]?.meta_img}`}
          />
          {/* <meta property="og:url" content={`${WebsiteUrl}`} /> */}
          <meta property="og:url" content={`${global.backendUrl}`} />
          <meta property="og:type" content="website" />
          <meta name="twitter:card" content={data2[0]?.meta_title} />
          <meta name="twitter:title" content={data2[0]?.meta_title} />
          <meta
            name="twitter:description"
            content={data2[0]?.meta_description}
          />

          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <link rel="favicon" href="/favicon.ico" />
          <meta
            name="twitter:image"
            content={`${global.imageURL}/nodeassets/${data2[0]?.meta_img}`}
          />
        </Helmet>
      )}
      <PageTitle sub="Blog" title="Blogs" />

      <section className="tf-blog">
        <div className="tf-container">
          <div className="row justify-content-around">
            <div className="col-md-8">
            {loading ? (  
                <div className="loading-container">
                  <span className='loader'></span>
                </div>
              ) : (
              <div className="main-content">
                {filteredData
                  .slice(indexOfFirstPost, indexOfLastPost)
                  .map((idx) => (
                    <article key={idx.id} className="tf-blog-item style-2">
                      <div className="image">
                        <Link to={`/blog-details-v1/${idx.blog_slug}`}>
                          <img
                            src={`${global.imageURL}/nodeassets/${idx.blog_img}`}
                            alt="ANTS"
                          />
                        </Link>
                      </div>

                      <div className="title">
                        <h5>
                          <Link to={`/blog-details-v1/${idx.blog_slug}`}>
                            {idx.blog_title}
                          </Link>
                        </h5>
                        <Link
                          to={`/blog-details-v1/${idx.blog_slug}`}
                          
                        >
                          <span className="tf-button style-1" >{idx.blog_category}</span>
                        </Link>
                      </div>
                   
                       <div style={customStyle}>
    {truncate(stripHTML(idx.blog_desc), { length: 230 })}
  </div>
                      {/* <p className="content">{idx.text}</p> */}

                      <div className="meta">
                        {/* <span className="admin">
                          {' '}
                          <i className="fal fa-user"></i>
                          Jhon Doe
                        </span> */}
                        {/* <span className="date">
                          <i className="far fa-clock"></i>
                          {idx.blog_postingDate}
                        </span> */}
                      </div>
                    </article>
                  ))}


              </div>
              )}
            </div>
            <div className="col-md-4">
              <div className="side-bar">
                <div className="widget widget-category">
                  <h4 className="widget-title">ANTS Blog - <br />
                  Latest ANTS News & Updates
                  </h4>
                  {/* <h6 className="widget-title"></h6> */}
                  {/* <ul>
                    {data3?.map((C, i) => (
                      <li
                        onClick={() => setcategoryChecker(C.category_name)}
                        style={{ cursor: 'pointer' }}
                      >
                        <a>{C?.category_name}</a>{' '}
                        <span>
                          {
                            data.filter(
                              (t) => t.blog_category == C.category_name
                            ).length
                          }
                        </span>
                      </li>
                    ))}
                    <li>
                      <Link to="#">NFT Creators</Link> <span>23</span>
                    </li>
                    <li>
                      <Link to="#">Rare Products</Link> <span>12</span>
                    </li>
                    <li>
                      <Link to="#">Rare Vedios</Link> <span>76</span>
                    </li>
                    <li>
                      <Link to="#">Digital Arts</Link> <span>123</span>
                    </li>
                    <li>
                      <Link to="#">App Music NFT’s</Link> <span>64</span>
                    </li>
                    <li>
                      <Link to="#">Application</Link> <span>108</span>
                    </li>
                    <li>
                      <Link to="#">Art</Link> <span>67</span>
                    </li>
                  </ul> */}

                  <div>Join the newsletter to receive the latest updates in your box</div>
                  <div className='d-flex ' style={{flexDirection:'column'}} id="subscribe-form">
                <input
                  type="email"
                  placeholder="Info@yourgmail.com"
                  value={email}
                  onChange={(e) => setemail(e.target.value)}
                  required=""
                  id=""
                  style={{marginBottom:'20px',marginTop:'20px'}}
                  className='rounded'
                />
                <button
                  className=""
                  id="subscribe-button"
                  disabled={isLoading}
                  onClick={handleJoin}
                >
                  Subscribe
                  {/* <i className="icon-fl-send"></i> */}
                </button>
              </div>
                </div>
                {/* <div className="widget widget-recent-post">
                  <h6 className="widget-title">Recent Post</h6>
                  <ul>
                    {dataRecent.map((idx) => (
                      <li key={idx.id}>
                        <div className="post-img">
                          <img src={idx.img} alt="Post New" />
                        </div>
                        <div className="post-content">
                          <h6 className="title">
                            <Link to="/blog-details-v2">{idx.title}</Link>
                          </h6>
                          <Link to="/blog-details-v2" className="post-category">
                            {idx.cate}
                          </Link>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="widget widget-tag ">
                  <h6 className="widget-title">Popular Tag</h6>
                  <ul>
                    <li>
                      <Link to="#">Bitcoin</Link>
                    </li>
                    <li>
                      <Link to="#">NFT</Link>
                    </li>
                    <li>
                      <Link to="#">Bids</Link>
                    </li>
                    <li>
                      <Link to="#">Digital</Link>
                    </li>
                    <li>
                      <Link to="#">Arts</Link>
                    </li>
                    <li>
                      <Link to="#">Maketplace</Link>
                    </li>
                    <li>
                      <Link to="#">Token</Link>
                    </li>
                    <li>
                      <Link to="#">Wallet</Link>
                    </li>
                    <li>
                      <Link to="#">Crypto</Link>
                    </li>
                  </ul>
                </div> */}
              </div>
              <div className="side-bar" style={{marginTop:'20px'}}>
                <div className="widget widget-category">
                  <h6 className="widget-title">Categories</h6>
                  <ul>
                    {data3?.map((C, i) => (
                      <li
                        onClick={() => setcategoryChecker(C.category_name)}
                        style={{ cursor: 'pointer' }}
                      >
                        <a>{C?.category_name}</a>{' '}
                        <span>
                          {
                            data.filter(
                              (t) => t.blog_category == C.category_name
                            ).length
                          }
                        </span>
                      </li>
                    ))}
                
                  </ul>
                </div>
               
              </div>
            </div>
            <ul className="pagination">
    {Array.from({
      length: Math.ceil(data.length / postsPerPage),
    }).map((_, index) => (
      <li 
      key={index} 
      className={activePage === index + 1 ? 'page-item active' : 'page-item'}
    >
       <button
    onClick={() => paginate(index + 1)}
    className="page-link"
    style={{
      ...(activePage === index + 1 ? {
        backgroundColor: '#fff',
        borderColor: '#0275d8',
        color: '#0275d8',
        borderWidth:'2px',
        fontWeight:'bold'
      } : {})
    }}
  >
    {index + 1}
  </button>
      </li>
    ))}
  </ul>
          </div>
        </div>
      </section>
    </>
  );
}

export default Blog02;
{
  /* <div className="load-more style-2">
                    <button className="tf-button loadmore" onClick={toggleShowAll}>
                      {
                        showAll ? 'Load Less' : 'Load more'
                      }
                    </button>
                  </div> */
}
