import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getWhitelist } from "../api/Api";
import axios from "axios";
import { toast } from "react-toastify";
import PageTitle from "../components/pagetitle/PageTitle";
import { useStateContext } from "../components/context/AppContext";
import Web3 from "web3";
import ABIStaking from "../utils/abi-staking";
import { stakingContractAddress } from "../utils/utils";
import JSBI from "jsbi";

export default function PrivateSaleModule() {
  const location = useLocation();
  const initialSale = location.state || {};
  const { saleData, setSaleData } = useStateContext();
  console.log("saleData", saleData);
  const [userAddress, setUserAddress] = useState("");
  const [tokenAmount, setTokenAmount] = useState("");
  const [sale, setSale] = useState(initialSale);
  const getSaleData = async () => {
    const res = await getWhitelist();
    if (res.data && res.data.length > 0) {
      setUserAddress(res.data[0].whitelist_id);
    }
  };
  const AccountInfo = localStorage.getItem("antsInformation");
  const parsedInfo = JSON.parse(AccountInfo);

  const handleBuyTokens = async () => {
    if (!tokenAmount || isNaN(tokenAmount) || Number(tokenAmount) <= 0) {
      toast.error("Please enter a valid token amount greater than 0");
      return;
    }
    if (!saleData.private_total_token || saleData.private_total_token <= 0) {
      toast.error("No tokens are available for purchase");
      return;
    }

    try {
      const web3 = new Web3(window.ethereum);
      await window.ethereum.enable();
      const accounts = await web3.eth.getAccounts();
      const account = accounts[0];
      console.log("Token Price:", sale.private_sale_token_price);

      if (
        !sale.private_sale_token_price ||
        isNaN(sale.private_sale_token_price)
      ) {
        toast.error("Token price is invalid");
        return;
      }

      const privateSaleId = sale.private_sale_id.toString();
      const tokenAmountStr = tokenAmount.toString();

      const contract = new web3.eth.Contract(
        ABIStaking,
        stakingContractAddress
      );

      const tokenPrice = JSBI.BigInt(sale.private_sale_token_price);
      const tokenAmountValue = JSBI.BigInt(tokenAmount);
      const totalCost = JSBI.multiply(tokenPrice, tokenAmountValue);
      console.log("totalCost (JSBI):", totalCost.toString()); // Safe as a string

      const balance = await web3.eth.getBalance(account);
      console.log("Wallet Balance:", balance);

      const saleData = await contract.methods
        .privateSales(privateSaleId)
        .call();
      console.log("Sale Data from Contract:", saleData);

      const gasPrice = await web3.eth.getGasPrice();
      console.log("gasPrice", gasPrice);

      
      const gasEstimate = await contract.methods
        .buyTokens(privateSaleId, tokenAmountStr)
        .estimateGas({ from: account, value: totalCost.toString() });
      console.log("Gas estimate:", gasEstimate);

      await contract.methods
        .buyTokens(privateSaleId, tokenAmountStr)
        .call({
          from: account,
          value: totalCost.toString(),
        });

      await contract.methods
        .buyTokens(privateSaleId, tokenAmountStr)
        .send({
          from: account,
          gasPrice: gasPrice,
          gas: gasEstimate,
          value: totalCost.toString(),
        })
        .on("receipt", (receipt) => {
          console.log("Transaction successful:", receipt);
          const updatedSaleData = {
            ...saleData,
            private_total_token:
              Number(saleData.private_total_token) - Number(tokenAmount),
          };
          console.log("saleData", saleData);
          console.log("updatedSaleData", updatedSaleData);

          setSaleData(updatedSaleData);
          // localStorage.setItem(
          //   "privateSaleData",
          //   JSON.stringify(updatedSaleData)
          // );

          toast.success("Tokens purchased successfully");
        })
        .on("error", (error) => {
          console.error("Transaction failed:", error);
          toast.error("Transaction failed. Please try again.");
        });
    } catch (error) {
      console.error("Error in handleBuyTokens:", error);
      toast.error("An unexpected error occurred");
    }
  };

  const formatDateTime = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: false,
    });
  };

  useEffect(() => {
    getSaleData();
  }, []);

  if (!saleData) {
    return <div>Loading sale information...</div>;
  }

  const customStyle = {
    color: "var(--primary-color5)",
    display: "flex",
    flexDirection: "row",
    paddingRight: "10px",
  };

  return (
    <>
      <PageTitle sub="Project" title="Private Sale Module" />

      <div className="topMain">
        <div className="tf-item-detail-inner ss">
          <div className="content">
            <div className="main">
              <div className="first">
                <div style={customStyle}>
                  <p style={{ paddingRight: "15px", customStyle }}>
                    Start Time:
                  </p>
                  <p>{formatDateTime(saleData.private_sale_start_time)}</p>
                </div>
                <div style={customStyle}>
                  <p style={{ paddingRight: "15px", customStyle }}>End Time:</p>
                  <p>{formatDateTime(saleData.private_sale_end_time)}</p>
                </div>
                <div style={customStyle}>
                  <p style={{ paddingRight: "15px", customStyle }}>
                    Available Tokens:
                  </p>
                  <p>{saleData.private_total_token}</p>
                </div>
                <div style={customStyle}>
                  <p style={{ paddingRight: "15px", customStyle }}>
                    {" "}
                    Price per token:
                  </p>
                  <p>{saleData.private_sale_token_price}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="tf-item-detail-inner ss">
          <div className="content">
            <div className="main">
              <div className="second">
                <div className="content-bottom">
                  <fieldset>
                    <input
                      type="number"
                      required
                      placeholder="Enter Your Token"
                      value={tokenAmount}
                      onChange={(e) => setTokenAmount(e.target.value)}
                      style={{ borderRadius: "40px", marginBottom: "20px" }}
                    />
                  </fieldset>
                  <div className="button">
                    <button className="tf-button" onClick={handleBuyTokens}>
                      Buy Tokens
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
